import axios from 'axios';
import qs from 'qs';
import { FAILURE, REQUEST, SUCCESS } from '../../../app/shared/reducers/action-type.util';
import { IActionForResponseEntity } from '../../../app/util/redux-interface';
import { HttpRequestStatus } from '../../../app/shared/model/enum/HttpRequestStatus';
import { IPageRequest } from '../../../app/shared/model/page.model';
import { CompanyChargeAmountVM, ICompany, ICompanyCancelCharge, ICompanyCharge, ICompanyCreateChargeVM } from '../../../app/shared/model/company';

export const ACTION_TYPES = {
  GET_COMPANY_BY_ID: 'company/GET_COMPANY_BY_ID',
  FETCH_COMPANY: 'company/FETCH_COMPANY',
  FETCH_COMPANY_USERS: 'company/FETCH_COMPANY_USERS',
  INSERT_COMPANY: 'company/INSERT_COMPANY',
  UPDATE_COMPANY: 'company/UPDATE_COMPANY',
  CREATE_COMPANY_USER: 'company/CREATE_COMPANY_USER',
  GET_COMPANY_WALLET: 'company/GET_COMPANY_WALLET',
  GET_COMPANY_WALLET_LOGS: 'company/GET_COMPANY_WALLET_LOGS',
  CREATE_COMPANY_WALLET: 'company/CREATE_COMPANY_WALLET',
  INCREASE_COMPANY_WALLET: 'company/INCREASE_COMPANY_WALLET',
  UPDATE_COMPANY_DISCOUNT_FEE: 'company/UPDATE_COMPANY_DISCOUNT_FEE',
  SAVE_KEIKEN_PAY_FEE: 'company/SAVE_KEIKEN_PAY_FEE',
  GET_KEIKEN_PAY_FEE: 'company/GET_KEIKEN_PAY_FEE',
  GET_COMPANIES_CHARGES: 'company/GET_COMPANIES_CHARGES',
  GET_COMPANY_CHARGES_CREATED: 'company/GET_COMPANY_CHARGES_CREATED',
  POST_COMPANY_CHARGE: 'company/POST_COMPANY_CHARGE',
  DELETE_COMPANY_CHARGE: 'company/DELETE_COMPANY_CHARGE',
  RESET: 'company/RESET'
};

const initialState = {
  // status
  getCompany: HttpRequestStatus.NOOP,
  getCompanies: HttpRequestStatus.NOOP,
  getCompanyUsersStatus: HttpRequestStatus.NOOP,
  saveCompany: HttpRequestStatus.NOOP,
  saveCompanyUser: HttpRequestStatus.NOOP,
  walletStatus: HttpRequestStatus.NOOP,
  createWalletStatus: HttpRequestStatus.NOOP,
  increaseWalletStatus: HttpRequestStatus.NOOP,
  discountFeeStatus: HttpRequestStatus.NOOP,
  keikenPayFeeStatus: HttpRequestStatus.NOOP,
  getKeikenPayFeeStatus: HttpRequestStatus.NOOP,
  keikenPayFees: null,
  companiesChargesStatus: HttpRequestStatus.NOOP,
  postCompanyChargeStatus: HttpRequestStatus.NOOP,
  getCompaniesChargesCreatedStatus: HttpRequestStatus.NOOP,
  deleteCompanyChargeStatus: HttpRequestStatus.NOOP,
  // contents
  company: null,
  wallet: null,
  companiesPage: null,
  page: null,
  companyUsers: null,
  companiesCharges: null as CompanyChargeAmountVM[] | null,
  companiesChargesCreated: null as ICompanyCharge[] | null
};

export type ICompanyState = Readonly<typeof initialState>;

// Reducer
// tslint:disable-next-line:cyclomatic-complexity
export default (state: ICompanyState = initialState, action: IActionForResponseEntity<ICompany>): ICompanyState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_COMPANY):
      return { ...initialState, getCompanies: HttpRequestStatus.ONGOING };
    case FAILURE(ACTION_TYPES.FETCH_COMPANY):
      return { ...initialState, getCompanies: HttpRequestStatus.ERROR };
    case SUCCESS(ACTION_TYPES.FETCH_COMPANY):
      return { ...initialState, getCompanies: HttpRequestStatus.SUCCESS, companiesPage: action.payload.data, page: action.payload.data };
    case REQUEST(ACTION_TYPES.GET_COMPANY_BY_ID):
      return { ...initialState, getCompany: HttpRequestStatus.ONGOING };
    case FAILURE(ACTION_TYPES.GET_COMPANY_BY_ID):
      return { ...initialState, getCompany: HttpRequestStatus.ERROR };
    case SUCCESS(ACTION_TYPES.GET_COMPANY_BY_ID):
      return { ...initialState, getCompany: HttpRequestStatus.SUCCESS, company: action.payload.data };
    // INSERT
    case REQUEST(ACTION_TYPES.INSERT_COMPANY):
      return { ...initialState, saveCompany: HttpRequestStatus.ONGOING };
    case FAILURE(ACTION_TYPES.INSERT_COMPANY):
      return { ...initialState, saveCompany: HttpRequestStatus.ERROR };
    case SUCCESS(ACTION_TYPES.INSERT_COMPANY):
      return { ...initialState, saveCompany: HttpRequestStatus.SUCCESS };
    // UPDATE
    case REQUEST(ACTION_TYPES.UPDATE_COMPANY):
      return { ...initialState, saveCompany: HttpRequestStatus.ONGOING };
    case FAILURE(ACTION_TYPES.UPDATE_COMPANY):
      return { ...initialState, saveCompany: HttpRequestStatus.ERROR };
    case SUCCESS(ACTION_TYPES.UPDATE_COMPANY):
      return { ...initialState, saveCompany: HttpRequestStatus.SUCCESS };
    case REQUEST(ACTION_TYPES.CREATE_COMPANY_USER):
      return { ...initialState, saveCompanyUser: HttpRequestStatus.ONGOING };
    case FAILURE(ACTION_TYPES.CREATE_COMPANY_USER):
      return { ...initialState, saveCompanyUser: HttpRequestStatus.ERROR };
    case SUCCESS(ACTION_TYPES.CREATE_COMPANY_USER):
      return { ...initialState, saveCompanyUser: HttpRequestStatus.SUCCESS };
    case REQUEST(ACTION_TYPES.GET_COMPANY_WALLET):
      return { ...initialState, walletStatus: HttpRequestStatus.ONGOING };
    case FAILURE(ACTION_TYPES.GET_COMPANY_WALLET):
      return { ...initialState, walletStatus: HttpRequestStatus.ERROR };
    case SUCCESS(ACTION_TYPES.GET_COMPANY_WALLET):
      return { ...initialState, walletStatus: HttpRequestStatus.SUCCESS, wallet: action.payload.data };
    case REQUEST(ACTION_TYPES.GET_COMPANY_WALLET_LOGS):
      return { ...initialState, walletStatus: HttpRequestStatus.ONGOING };
    case FAILURE(ACTION_TYPES.GET_COMPANY_WALLET_LOGS):
      return { ...initialState, walletStatus: HttpRequestStatus.ERROR };
    case SUCCESS(ACTION_TYPES.GET_COMPANY_WALLET_LOGS):
      return { ...initialState, walletStatus: HttpRequestStatus.SUCCESS, wallet: action.payload.data };
    case REQUEST(ACTION_TYPES.INCREASE_COMPANY_WALLET):
      return { ...initialState, increaseWalletStatus: HttpRequestStatus.ONGOING };
    case FAILURE(ACTION_TYPES.INCREASE_COMPANY_WALLET):
      return { ...initialState, increaseWalletStatus: HttpRequestStatus.ERROR };
    case SUCCESS(ACTION_TYPES.INCREASE_COMPANY_WALLET):
      return { ...initialState, increaseWalletStatus: HttpRequestStatus.SUCCESS };
    case REQUEST(ACTION_TYPES.CREATE_COMPANY_WALLET):
      return { ...initialState, createWalletStatus: HttpRequestStatus.ONGOING };
    case FAILURE(ACTION_TYPES.CREATE_COMPANY_WALLET):
      return { ...initialState, createWalletStatus: HttpRequestStatus.ERROR };
    case SUCCESS(ACTION_TYPES.CREATE_COMPANY_WALLET):
      return { ...initialState, createWalletStatus: HttpRequestStatus.SUCCESS, wallet: action.payload.data };
    case REQUEST(ACTION_TYPES.UPDATE_COMPANY_DISCOUNT_FEE):
      return { ...initialState, discountFeeStatus: HttpRequestStatus.ONGOING };
    case FAILURE(ACTION_TYPES.UPDATE_COMPANY_DISCOUNT_FEE):
      return { ...initialState, discountFeeStatus: HttpRequestStatus.ERROR };
    case SUCCESS(ACTION_TYPES.UPDATE_COMPANY_DISCOUNT_FEE):
      return { ...initialState, discountFeeStatus: HttpRequestStatus.SUCCESS };
    case REQUEST(ACTION_TYPES.SAVE_KEIKEN_PAY_FEE):
      return { ...initialState, keikenPayFeeStatus: HttpRequestStatus.ONGOING };
    case FAILURE(ACTION_TYPES.SAVE_KEIKEN_PAY_FEE):
      return { ...initialState, keikenPayFeeStatus: HttpRequestStatus.ERROR };
    case SUCCESS(ACTION_TYPES.SAVE_KEIKEN_PAY_FEE):
      return { ...initialState, keikenPayFeeStatus: HttpRequestStatus.SUCCESS };
    case REQUEST(ACTION_TYPES.FETCH_COMPANY_USERS): {
      return { ...initialState, getCompanyUsersStatus: HttpRequestStatus.ONGOING };
    }
    case FAILURE(ACTION_TYPES.GET_KEIKEN_PAY_FEE):
      return { ...initialState, getKeikenPayFeeStatus: HttpRequestStatus.ERROR };
    case SUCCESS(ACTION_TYPES.GET_KEIKEN_PAY_FEE):
      return { ...initialState, keikenPayFees: action.payload.data, getKeikenPayFeeStatus: HttpRequestStatus.SUCCESS };
    case REQUEST(ACTION_TYPES.GET_KEIKEN_PAY_FEE): {
      return { ...initialState, getKeikenPayFeeStatus: HttpRequestStatus.ONGOING };
    }
    case FAILURE(ACTION_TYPES.FETCH_COMPANY_USERS): {
      return { ...initialState, getCompanyUsersStatus: HttpRequestStatus.ERROR };
    }
    case SUCCESS(ACTION_TYPES.FETCH_COMPANY_USERS): {
      return { ...initialState, getCompanyUsersStatus: HttpRequestStatus.SUCCESS, companyUsers: action.payload.data };
    }
    case REQUEST(ACTION_TYPES.GET_COMPANIES_CHARGES): {
      return { ...initialState, companiesChargesStatus: HttpRequestStatus.ONGOING };
    }
    case FAILURE(ACTION_TYPES.GET_COMPANIES_CHARGES): {
      return { ...initialState, companiesChargesStatus: HttpRequestStatus.ERROR };
    }
    case SUCCESS(ACTION_TYPES.GET_COMPANIES_CHARGES): {
      return { ...state, companiesChargesStatus: HttpRequestStatus.SUCCESS, companiesCharges: action.payload.data as CompanyChargeAmountVM[] };
    }
    case REQUEST(ACTION_TYPES.POST_COMPANY_CHARGE): {
      return { ...initialState, postCompanyChargeStatus: HttpRequestStatus.ONGOING };
    }
    case FAILURE(ACTION_TYPES.POST_COMPANY_CHARGE): {
      return { ...initialState, postCompanyChargeStatus: HttpRequestStatus.ERROR };
    }
    case SUCCESS(ACTION_TYPES.POST_COMPANY_CHARGE): {
      return { ...initialState, postCompanyChargeStatus: HttpRequestStatus.SUCCESS };
    }
    case REQUEST(ACTION_TYPES.GET_COMPANY_CHARGES_CREATED): {
      return { ...initialState, getCompaniesChargesCreatedStatus: HttpRequestStatus.ONGOING };
    }
    case FAILURE(ACTION_TYPES.GET_COMPANY_CHARGES_CREATED): {
      return { ...initialState, getCompaniesChargesCreatedStatus: HttpRequestStatus.ERROR };
    }
    case SUCCESS(ACTION_TYPES.GET_COMPANY_CHARGES_CREATED): {
      return { ...state, getCompaniesChargesCreatedStatus: HttpRequestStatus.SUCCESS, companiesChargesCreated: action.payload.data as ICompanyCharge[] };
    }
    case REQUEST(ACTION_TYPES.DELETE_COMPANY_CHARGE): {
      return { ...state, deleteCompanyChargeStatus: HttpRequestStatus.ONGOING };
    }
    case FAILURE(ACTION_TYPES.DELETE_COMPANY_CHARGE): {
      return { ...state, deleteCompanyChargeStatus: HttpRequestStatus.ERROR };
    }
    case SUCCESS(ACTION_TYPES.DELETE_COMPANY_CHARGE): {
      return { ...state, deleteCompanyChargeStatus: HttpRequestStatus.SUCCESS };
    }
    case ACTION_TYPES.RESET:
      return { ...initialState };
    default:
      return state;
  }
};

const apiUrl = '/api/companies';

export const getCompanyById = companyId => ({ type: ACTION_TYPES.GET_COMPANY_BY_ID, payload: axios.get(`${apiUrl}/${companyId}`) });

export const createCompanyUser = (companyId, companyUser) => ({
  type: ACTION_TYPES.CREATE_COMPANY_USER,
  payload: axios.post(`${apiUrl}/${companyId}/users`, companyUser)
});

export const getWallet = companyId => ({
  type: ACTION_TYPES.GET_COMPANY_WALLET,
  payload: axios.get(`${apiUrl}/${companyId}/wallet`)
});
export const getWalletLogs = companyId => ({
  type: ACTION_TYPES.GET_COMPANY_WALLET_LOGS,
  payload: axios.get(`${apiUrl}/${companyId}/wallet/logs`)
});

export const createCompanyWallet = companyId => ({
  type: ACTION_TYPES.CREATE_COMPANY_WALLET,
  payload: axios.post(`${apiUrl}/${companyId}/wallet`)
});

export const increaseWallet = (companyId: number, billing) => ({
  type: ACTION_TYPES.INCREASE_COMPANY_WALLET,
  payload: axios.post(`${apiUrl}/${companyId}/increase-balance`, billing)
});

export const saveCompanyDiscountFee = (companyId, discountFee) => ({
  type: ACTION_TYPES.UPDATE_COMPANY_DISCOUNT_FEE,
  payload: axios.put(`${apiUrl}/${companyId}/discount-fee`, discountFee)
});

export const saveCompanyKeikenPayFee = (companyId, keikenPayFee) => ({
  type: ACTION_TYPES.SAVE_KEIKEN_PAY_FEE,
  payload: axios.post(`${apiUrl}/${companyId}/keiken-pay-fee`, keikenPayFee)
});

export const getCompanyKeikenPayFee = companyId => ({
  type: ACTION_TYPES.GET_KEIKEN_PAY_FEE,
  payload: axios.get(`${apiUrl}/${companyId}/keiken-pay-fee`)
});

export const fetchCompany = (page: IPageRequest, params?: any) => ({
  type: ACTION_TYPES.FETCH_COMPANY,
  payload: axios.get(`${apiUrl}?size=${page.size}&page=${page.page}&${qs.stringify(params)}`)
});

export const insertCompany = (company: ICompany) => ({
  type: ACTION_TYPES.INSERT_COMPANY,
  payload: axios.post(`${apiUrl}`, company)
});

export const updateCompany = (company: ICompany) => ({
  type: ACTION_TYPES.UPDATE_COMPANY,
  payload: axios.put(`${apiUrl}`, company)
});

export const getCompanyUsers = companyId => ({
  type: ACTION_TYPES.FETCH_COMPANY_USERS,
  payload: axios.get(`${apiUrl}/${companyId}/users/me`)
});

export const getCompaniesCharges = (month: number, year: number) => {
  const requestUrl = `${apiUrl}/charge-amount?${qs.stringify({ month, year })}`;
  return {
    type: ACTION_TYPES.GET_COMPANIES_CHARGES,
    payload: axios.get<CompanyChargeAmountVM[]>(requestUrl)
  };
};

export const postCompanyCharge = (objToSend: ICompanyCreateChargeVM) => ({
  type: ACTION_TYPES.POST_COMPANY_CHARGE,
  payload: axios.post(`${apiUrl}/charge`, objToSend)
});

export const deleteCompanyCharge = (objToSend: ICompanyCancelCharge) => ({
  type: ACTION_TYPES.DELETE_COMPANY_CHARGE,
  payload: axios.delete(`${apiUrl}/charges`, { data: objToSend })
});

export const getCompaniesChargesCreated = (month: number, year: number) => {
  const requestUrl = `${apiUrl}/charges?${qs.stringify({ month, year })}`;
  return {
    type: ACTION_TYPES.GET_COMPANY_CHARGES_CREATED,
    payload: axios.get<ICompanyCharge[]>(requestUrl)
  };
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
