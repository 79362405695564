import * as React from 'react';
import { connect } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { RouteComponentProps, Link } from 'react-router-dom';
import './partner-place-manage.scss';
import { forceUpdateIndexPartnerPlace, getPartnerPlaceById, reset } from 'app/entities/partner-place/partner-place-redux';
import InputField from 'app/components/input-field/InputField';
import { translate, Translate } from 'react-jhipster';
import { Row, Col, Button as RButton, UncontrolledTooltip, Label, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { ChangeEvent } from 'react';
import Button from 'app/components/button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IPartnerPlace } from 'app/shared/model/partner-place.model';
import { PhoneType } from 'app/shared/model/phone.model';
import { hideModal, showModal } from 'app/components/modal/modal-action';
import { fetchAddresses } from 'app/entities/address/address-reducer';
import { updatePartner, reset as updateReset } from 'app/entities/partner/partner-reducer';
import { IAddress, IAddressUtils } from 'app/shared/model/address.model';
import { MODAL_TYPE_SELECT_OPTION } from 'app/components/modal/modal-types';
import { IPartner, IPartnerUpdate } from 'app/shared/model/partner';
import { toast } from 'react-toastify';
import StringUtils from 'app/util/StringUtils';
import _ from 'lodash';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import moment from 'moment';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import BillingsTable from '../../../components/PartnerBillingsTable';
import { APP_LOCAL_DATE_FORMAT } from '../../../../app/config/constants';
import { initNewPassword, reset as userReduxReset } from '../../../entities/user/user-redux';
import { BASE_LP_URL } from '../../../../app/config/constants';
import { getProfessionalCouncilDocuments } from 'app/entities/professional-council-document/professional-council-document-redux';
import { getSpecialties, getSpecialtiesByDocumentId, clearSpecialtiesByDocument } from 'app/entities/specialty/specialty-redux';
import { GetSpecialtyDTO } from 'app/shared/model/specialty';
import { Specialty } from '../../../shared/model/specialty';

export interface IPartnerPlaceManageProps
  extends StateProps,
  DispatchProps,
  RouteComponentProps<{
    id?: string;
  }, any, {} | any> { }

export interface IPartnerPlaceManageState {
  partnerPlace?: IPartnerPlace;
  partner?: IPartnerUpdate;
  loading: boolean;
  create: boolean;
  valids: IPartnerManageValids;
  errors: IPartnerManageFieldsError;
  idNumberMustBeCnpjMask: boolean;
  idNumberMask: string;
  identificationNumber?: string;
  requestingReset: boolean;
  professionalCouncilDocumentId?: number;
  selectedSpecialties: Specialty[];
  specialtiesDropdownOpen: boolean;
}

interface IPartnerManageValids {
  ['partner.name']?: boolean;
  ['partner.user.email']?: boolean;
  ['partnerPlace.fantasyName']?: boolean;
  ['partnerPlace.socialReason']?: boolean;
  ['partnerPlace.customUrl']?: boolean;
  ['partnerPlace.orderPriority']?: boolean;
  ['partnerPlace.address']?: boolean;
  ['partnerPlace.image']?: boolean;
  ['partnerPlace.description']?: boolean;
  ['partnerPlace.cref']?: boolean;
  ['identificationNumber']?: boolean;
}

interface IPartnerManageFieldsError {
  ['partner.name']?: boolean;
  ['partner.user.email']?: boolean;
  ['partnerPlace.fantasyName']?: boolean;
  ['partnerPlace.socialReason']?: boolean;
  ['partnerPlace.customUrl']?: boolean;
  ['partnerPlace.orderPriority']?: boolean;
  ['partnerPlace.address']?: boolean;
  ['partnerPlace.image']?: boolean;
  ['partnerPlace.description']?: boolean;
  ['partnerPlace.cref']?: boolean;
  ['identificationNumber']?: boolean;
}

export class IPartnerPlaceManage extends React.Component<IPartnerPlaceManageProps, IPartnerPlaceManageState> {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      create: false,
      errors: {},
      valids: {},
      idNumberMustBeCnpjMask: false,
      idNumberMask: '99.999.999/9999-99',
      requestingReset: false,
      selectedSpecialties: [],
      specialtiesDropdownOpen: false
    };
  }

  componentWillMount() {
    const id = parseInt(this.props.match.params?.id, 10);
    if (id) {
      this.props.getPartnerPlaceById(id);
      this.setState({ create: false });
    } else {
      this.setState({ create: true });
    }

    // Fetch all professional council documents and specialties
    this.props.getProfessionalCouncilDocuments();
    this.props.getSpecialties();
  }

  componentWillReceiveProps(newProps: IPartnerPlaceManageProps) {
    if (
      newProps.updateIndexStatus == HttpRequestStatus.SUCCESS ||
      newProps.updateIndexStatus == HttpRequestStatus.ERROR
    ) {
      this.setState({ loading: false });
      this.props.reset();
    }

    if (newProps.partnerPlace != null) {
      const partnerPlace = newProps.partnerPlace;
      partnerPlace.phone = partnerPlace?.phones?.[0];
      partnerPlace.address = partnerPlace?.addresses?.[0];

      const partner = partnerPlace.partners?.[0];
      if (partner.birthDate) {
        partner.birthDate = moment(partner.birthDate).format('DD/MM/YYYY');
      }
      this.setState({
        partnerPlace,
        // @ts-ignore
        partner,
        selectedSpecialties: partnerPlace.specialties || []
      }, () => {
        this.findMatchingDocumentAndFetchSpecialties();
        this.props.reset();
      });
    }

    if (newProps.saveAdminPartnerStatus == HttpRequestStatus.SUCCESS) {
      toast.success(translate('partner.edit.messages.success'), {
        onClose: () => this.props.history.goBack(),
        autoClose: 5000
      });
      this.props.updateReset();
    }
  }

  findMatchingDocumentAndFetchSpecialties = () => {
    const { partnerPlace } = this.state;
    const { professionalCouncilDocuments } = this.props;

    if (!partnerPlace || !professionalCouncilDocuments || professionalCouncilDocuments.length === 0) {
      return;
    }

    // Check which document the partner has (CRP, CREF, CRN, etc.)
    const documentTypes = [
      { field: 'cref', code: 'CREF' },
      { field: 'crn', code: 'CRN' },
      { field: 'crp', code: 'CRP' },
      { field: 'crefito', code: 'CREFITO' }
    ];

    let foundMatch = false;

    for (const docType of documentTypes) {
      if (partnerPlace[docType.field]) {
        // Find matching document in professionalCouncilDocuments
        const matchingDocument = professionalCouncilDocuments.find(
          doc => doc.documentCode === docType.code
        );

        if (matchingDocument && matchingDocument.id) {
          // Set the document ID in state and fetch specialties for this document
          this.setState({ professionalCouncilDocumentId: matchingDocument.id });
          this.props.getSpecialtiesByDocumentId(matchingDocument.id);
          foundMatch = true;
          break;
        }
      }
    }

    // If no match was found, clear any previously loaded specialties
    if (!foundMatch) {
      // Clear the specialties in redux store
      this.props.clearSpecialtiesByDocument();
      this.setState({
        professionalCouncilDocumentId: undefined,
        // Keep only the specialties that are already assigned to this partner
        selectedSpecialties: this.state.selectedSpecialties
      });
    }
  };

  handleIdentificationNumberChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const identificationNumber = event.target.value;
    this.setState(prevState => ({
      ...prevState,
      identificationNumber,
      errors: { ...prevState.errors, identificationNumber: false },
      valids: { ...prevState.valids, identificationNumber: false }
    }));
  };

  handleIdentificationNumberOnKeyPressed = () => {
    const onlyNumbersIdNumber = this.state.identificationNumber.replace(/\D/g, '');

    if (onlyNumbersIdNumber.length < 10) {
      this.setState({
        idNumberMustBeCnpjMask: false,
        idNumberMask: '999.999.999-99',
        errors: { ...this.state.errors, identificationNumber: false },
        valids: { ...this.state.valids, identificationNumber: false }
      });
      return;
    }

    if (onlyNumbersIdNumber.length === 10 && !this.state.idNumberMustBeCnpjMask) {
      this.setState({
        idNumberMustBeCnpjMask: true,
        errors: { ...this.state.errors, identificationNumber: false },
        valids: { ...this.state.valids, identificationNumber: false }
      });
      return;
    }

    if (this.state.idNumberMustBeCnpjMask) {
      this.setState({
        idNumberMask: '99.999.999/9999-99',
        errors: { ...this.state.errors, identificationNumber: false },
        valids: { ...this.state.valids, identificationNumber: false }
      });
      return;
    }
  };

  handleIdentificationNumberOnKeyDownPressed = event => {
    if (event.keyCode === 8 || event.keyCode === 46) {
      const onlyNumbersIdNumber = this.state.identificationNumber.replace(/\D/g, '');
      if (this.state.idNumberMustBeCnpjMask && onlyNumbersIdNumber.length === 12) {
        this.setState({ idNumberMustBeCnpjMask: false, idNumberMask: '999.999.999-99' });
        return;
      }
    }
  };

  handleEventChange = (object: 'partner' | 'partnerPlace', field: string, event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = event.target.value;
    this.handleValueChange(object, field, value);
  }

  handleValueChange = (object: 'partner' | 'partnerPlace', field: string, value?: string | number | boolean) => {
    const validatorField = `${object}.${field}`;
    const newObjectValues = this.state?.[object];
    if (field.includes('.')) {
      const fields = field.split('.');
      if (fields.length == 2) {
        newObjectValues[fields[0]][fields[1]] = value;
      }
    } else {
      newObjectValues[field] = value;
    }
    this.setState(prevState => ({
      ...prevState,
      [object]: newObjectValues,
      errors: { ...prevState.errors, [validatorField]: false },
      valids: { ...prevState.valids, [validatorField]: false }
    }));
  }

  handleOnPhoneChanged = (field: 'number' | 'type', event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    let value = event.target.value;
    if (field == 'type') {
      value = Object.keys(PhoneType).find(key => PhoneType[key] === value) as PhoneType;
    }
    this.setState(prevState => ({
      partnerPlace: {
        ...prevState.partnerPlace,
        phone: {
          ...prevState.partnerPlace?.phone,
          [field]: value
        }
      }
    }));
  };

  handleOnAddressPressed = () => {
    this.props.showModal(MODAL_TYPE_SELECT_OPTION, {
      displayProperty: [['street'], ['number'], ['district'], ['city'], ['zipcode']],
      title: translate('partner.register.labels.modal.address'),
      waitToPerformRequest: true,
      action: this.props.fetchAddresses,
      placeholderInput: translate('partner.register.placeholders.modal.address'),
      stateAction: 'addresses',
      cancelCallback: () => this.props.hideModal(),
      saveCallback: (item: any) => this.handleAddressSelected(item)
    });
  };

  handleAddressSelected = (item: IAddress) => {
    this.props.hideModal();
    this.setState(prevState => ({
      ...prevState,
      partnerPlace: {
        ...prevState.partnerPlace,
        address: item
      },
      errors: { ...prevState.errors, address: false },
      valids: { ...prevState.valids, address: false }
    }));
  };

  toggleSpecialtiesDropdown = () => {
    this.setState(prevState => ({
      specialtiesDropdownOpen: !prevState.specialtiesDropdownOpen
    }));
  };

  handleSelectSpecialty = (specialty: Specialty) => {
    const exists = this.state.selectedSpecialties.some(s => s.id === specialty.id);

    if (exists) {
      // Remove specialty if already selected
      this.setState(prevState => ({
        selectedSpecialties: prevState.selectedSpecialties.filter(s => s.id !== specialty.id)
      }));
    } else {
      // Add specialty if not already selected
      this.setState(prevState => ({
        selectedSpecialties: [...prevState.selectedSpecialties, specialty]
      }));
    }
  };

  isSpecialtySelected = (specialtyId: number) => {
    return this.state.selectedSpecialties.some(s => s.id === specialtyId);
  };

  handleConfirmButtonPressed = () => {
    const [checkApproved, valids, errors] = this.validateDataToApprove();
    if (this.state.partner.approved == true && checkApproved == false) {
      toast.warn('Dados incompletos para aprovar parceiro');
      this.setState({ valids, errors });
    }

    const partnerPlace = {
      ...this.state.partnerPlace,
      phones: [this.state.partnerPlace?.phone],
      addresses: [this.state.partnerPlace?.address],
      partners: undefined,
      address: undefined,
      phone: undefined,
      specialties: this.state.selectedSpecialties
    };

    const partner: IPartnerUpdate = {
      ...this.state.partner,
      birthDate: this.state.partner.birthDate ?
        moment(this.state.partner.birthDate).toDate() : undefined,
      cpf: !_.isEmpty(this.state.partner?.cpf) ? this.state.partner?.cpf?.replace(/\D/, '') : undefined,
      partnerPlace
    };

    this.props.updatePartner(partner);
  };

  handleBackButtonPressed = () => {
    this.props.history.goBack();
  };

  handleImageChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (ev: any) => {
      const newImage = this.state.partnerPlace?.image || {};
      const contentType = selectedFile.type;
      newImage.contentType = contentType;
      newImage.fileName = selectedFile.name;
      newImage.file = ev.target.result.split(',')[1];
      this.setState(prevState => ({ partnerPlace: { ...prevState.partnerPlace, image: newImage } }));
    };

    reader.readAsDataURL(selectedFile);
  };

  handleCoverImageChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (ev: any) => {
      const newImage = this.state.partnerPlace?.coverImage || {};
      const contentType = selectedFile.type;
      newImage.contentType = contentType;
      newImage.fileName = selectedFile.name;
      newImage.file = ev.target.result.split(',')[1];
      this.setState(prevState => ({ partnerPlace: { ...prevState.partnerPlace, coverImage: newImage } }));
    };

    reader.readAsDataURL(selectedFile);
  };

  validateDataToApprove = (): [boolean, IPartnerManageValids, IPartnerManageFieldsError] => {
    const fieldValids = {
      ['partner.name']: true,
      ['partner.user.email']: true,
      ['partnerPlace.fantasyName']: true,
      ['partnerPlace.socialReason']: true,
      ['partnerPlace.customUrl']: true,
      ['partnerPlace.orderPriority']: true,
      ['partnerPlace.address']: true,
      ['partnerPlace.image']: true,
      ['partnerPlace.description']: true,
      ['partnerPlace.cref']: true
    };
    const fieldError = {
      ['partner.name']: false,
      ['partner.user.email']: false,
      ['partnerPlace.fantasyName']: false,
      ['partnerPlace.socialReason']: false,
      ['partnerPlace.customUrl']: false,
      ['partnerPlace.orderPriority']: false,
      ['partnerPlace.address']: false,
      ['partnerPlace.image']: false,
      ['partnerPlace.description']: false,
      ['partnerPlace.cref']: false
    };
    if (StringUtils.isStringInvalid(this.state.partner?.name)) {
      fieldError['partner.name'] = true;
      fieldValids['partner.name'] = false;
    }
    if (!StringUtils.isEmailValid(this.state.partner?.user?.email) || StringUtils.isStringInvalid(this.state.partner?.user?.email)) {
      fieldError['partner.user.email'] = true;
      fieldValids['partner.user.email'] = false;
    }
    if (StringUtils.isStringInvalid(this.state.partnerPlace?.fantasyName)) {
      fieldError['partnerPlace.fantasyName'] = true;
      fieldValids['partnerPlace.fantasyName'] = false;
    }
    if (StringUtils.isStringInvalid(this.state.partnerPlace?.socialReason)) {
      fieldError['partnerPlace.socialReason'] = true;
      fieldValids['partnerPlace.socialReason'] = false;
    }
    if (!StringUtils.isCustomUrl(this.state.partnerPlace?.customUrl)) {
      fieldError['partnerPlace.customUrl'] = true;
      fieldValids['partnerPlace.customUrl'] = false;
    }
    if (StringUtils.isStringInvalid(this.state.partnerPlace?.description)) {
      fieldError['partnerPlace.description'] = true;
      fieldValids['partnerPlace.description'] = false;
    }
    if (StringUtils.isStringInvalid(this.state.partnerPlace?.orderPriority) && this.state.partnerPlace?.orderPriority >= 0) {
      fieldError['partnerPlace.orderPriority'] = true;
      fieldValids['partnerPlace.orderPriority'] = false;
    }
    if (
      StringUtils.isStringInvalid(this.state.partnerPlace?.cref) &&
      StringUtils.isStringInvalid(this.state.partnerPlace?.crn) &&
      StringUtils.isStringInvalid(this.state.partnerPlace?.crp)
    ) {
      fieldError['partnerPlace.cref'] = true;
      fieldError['partnerPlace.crn'] = true;
      fieldError['partnerPlace.crp'] = true;
      fieldValids['partnerPlace.cref'] = false;
      fieldValids['partnerPlace.crn'] = false;
      fieldValids['partnerPlace.crp'] = false;
    }
    if (
      StringUtils.isStringInvalid(this.state.partnerPlace?.address?.zipcode) ||
      StringUtils.isStringInvalid(this.state.partnerPlace?.address?.street) ||
      StringUtils.isStringInvalid(this.state.partnerPlace?.address?.number) ||
      !StringUtils.isOnlyNumber(this.state.partnerPlace?.address?.number) ||
      StringUtils.isStringInvalid(this.state.partnerPlace?.address?.geoPoint?.latitude) ||
      StringUtils.isStringInvalid(this.state.partnerPlace?.address?.geoPoint?.longitude)
    ) {
      fieldError['partnerPlace.address'] = true;
      fieldValids['partnerPlace.address'] = false;
    }
    return [
      !Object.values(fieldError).some(it => it == true),
      fieldValids,
      fieldError
    ];
  }

  private forceUpdateElasticIndex() {
    if (this.state?.partnerPlace?.id && !this.state.loading) {
      this.setState({ loading: true }, () => {
        this.props.forceUpdateIndexPartnerPlace(this.state?.partnerPlace?.id);
      });
    }
  }

  updateErrorsFields() {
    const [someError, valids, errors] = this.validateDataToApprove();
    this.setState(prev => ({
      ...prev,
      valids,
      errors
    }));
  }

  private handleInitNewPassword = async () => {
    this.setState({ requestingReset: true });

    await this.props.initNewPassword(this.state?.partner?.user?.email);

    if (this.props.initNewPasswordStatus === HttpRequestStatus.SUCCESS) {
      toast.success('Reset key solicitada com sucesso', {
        autoClose: 3000, onClose: () => {
          this.props.resetNewPassword();
          this.props.getPartnerPlaceById(this.state?.partnerPlace?.id);
        }
      });
    }

    this.setState({ requestingReset: false });
  };

  render() {
    const partnerPlace = this.state?.partnerPlace;
    const partner = this.state?.partner;
    const fullAddress = IAddressUtils.mapAddressToString(partnerPlace?.addresses?.[0]);
    const readyToApprove = this.validateDataToApprove()[0];

    const imageSrc = partnerPlace?.image?.file != null
      ? `data:image/png;base64, ${partnerPlace.image.file}`
      : partnerPlace?.image?.imageUrl != null
        ? partnerPlace.image.imageUrl
        : undefined;

    const coverImageSrc = partnerPlace?.coverImage?.file != null
      ? `data:image/png;base64, ${partnerPlace.coverImage.file}`
      : partnerPlace?.coverImage?.imageUrl != null
        ? partnerPlace.coverImage.imageUrl
        : undefined;

    const partnerBillingAccountId = partnerPlace?.billingAccount?.id;

    const resetKey = partner?.user?.resetKey;
    const resetDate = partner?.user?.resetDate;
    const urlNewPassword = `${BASE_LP_URL}/entrar/redefinir-senha/${resetKey}`;

    return (
      <>
        <div className={'form-container'}>
          <div className={'title-screen-container'}>
            <div onClick={() => this.props.history.goBack()}>
              <FontAwesomeIcon className={'back-arrow-icon'} icon={'arrow-left'} />
            </div>
            <label className={'parnter-manage-title'}>
              <Translate contentKey={this.state.create ? 'partnerPlaceManage.title.create' : 'partnerPlaceManage.title.edit'} />
            </label>

            <div className="marginL5">
              <RButton id={`update-index-partner-place`} color="warning" className={'centered-content margin1'} onClick={() => this.forceUpdateElasticIndex()}>
                <FontAwesomeIcon className="marginR2" icon={faUpload as IconProp} />
                Atualizar ElasticSearch
              </RButton>
              <UncontrolledTooltip target={`update-index-partner-place`}>Forçar atualizar indice no ElasticSearch</UncontrolledTooltip>
            </div>
          </div>
          <Row>
            <Col>
              <label className={'partner-header-label'}>
                <Translate contentKey={'partner.register.labels.titlePartner'} />
              </label>

              <div className={'partner-register-input-divisor'}>
                <InputField
                  title={translate('partner.register.labels.name')}
                  onChange={e => this.handleEventChange('partner', 'name', e)}
                  valid={this.state.valids?.['partner.name']}
                  error={this.state.errors?.['partner.name']}
                  inputClassName={'partner-register-input'}
                  value={partner?.name ?? ''}
                  placeholder={'partner.register.placeholders.name'}
                />
              </div>

              <div className={'partner-register-input-divisor'}>
                <InputField
                  title={translate('partner.register.labels.email')}
                  onChange={e => this.handleEventChange('partner', 'user.email', e)}
                  valid={this.state.valids?.['partner.user.email']}
                  error={this.state.errors?.['partner.user.email']}
                  inputClassName={'partner-register-input'}
                  value={partner?.user?.email ?? ''}
                  placeholder={'partner.register.placeholders.email'}
                />
              </div>

              <div className={'partner-register-input-divisor'}>
                <InputField
                  onChange={e => this.handleEventChange('partnerPlace', 'cpf', e)}
                  valid={this.state.valids?.['partnerPlace.cpf']}
                  error={this.state.errors?.['partnerPlace.cpf']}
                  value={partnerPlace?.cpf ?? ''}
                  inputClassName={'partner-register-input'}
                  title={translate('partner.register.labels.cpf')}
                  placeholder={'partner.register.placeholders.cpf'}
                  maskFormat={'999.999.999-99'}
                  isMaskRequired
                />
              </div>

              <div className={'partner-register-input-divisor'}>
                <InputField
                  title={translate('partner.register.labels.birthDate')}
                  onChange={e => this.handleEventChange('partner', 'birthDate', e)}
                  valid={this.state.valids?.['partner.birthDate']}
                  error={this.state.errors?.['partner.birthDate']}
                  inputClassName={'partner-register-input'}
                  value={partner?.birthDate?.toString?.() ?? ''}
                  placeholder={'partner.register.placeholders.birthDate'}
                  maskFormat={'99/99/9999'}
                  isMaskRequired
                />
              </div>
              <label className={'partner-header-label marginT10'}>
                <Translate contentKey={'partner.edit.labels.orderPriority'} />
              </label>
              <div className={'partner-register-input-divisor-horizontal-higher'}>
                <InputField
                  title={translate('partner.edit.labels.orderPriority')}
                  onChange={e => this.handleEventChange('partnerPlace', 'orderPriority', e)}
                  valid={this.state.valids?.['partnerPlace.orderPriority']}
                  error={this.state.errors?.['partnerPlace.orderPriority']}
                  inputClassName={'partner-register-two-inputs'}
                  value={partnerPlace?.orderPriority ?? ''}
                  placeholder={'partner.edit.placeholders.orderPriority'}
                  type={'number'}
                />
              </div>
              <label className={'partner-header-label marginT10'}>
                <Translate contentKey={'partner.register.labels.titleCustomUrl'} />
              </label>
              <div className={'partner-register-input-divisor'}>
                <InputField
                  title={translate('partner.register.labels.customUrl')}
                  onChange={e => this.handleEventChange('partnerPlace', 'customUrl', e)}
                  valid={this.state.valids?.['partnerPlace.customUrl']}
                  error={this.state.errors?.['partnerPlace.customUrl']}
                  inputClassName={'partner-register-input'}
                  value={partnerPlace?.customUrl ?? ''}
                  placeholder={'partner.register.placeholders.customUrl'}
                />
              </div>

              <div className={'partner-register-input-divisor-horizontal-container'}>
                <label className={'partner-header-label marginT10'}>
                  <Translate contentKey={'partner.register.labels.readyToApprove'} />
                </label>
                <p>
                  {readyToApprove ? 'Sim' : 'Não'}
                </p>
              </div>

              <div className={'partner-register-input-divisor-horizontal-container'}>
                <label className={'partner-header-label marginT5'}>
                  Estado do parceiro
                </label>
                <div className={'category-manage-inner-addon__radio'}>
                  <input checked={partner?.approved} onClick={() => {
                    if (!readyToApprove) {
                      toast.warn('Dados insufientes para ativar o parceiro');
                      this.handleValueChange('partner', 'approved', false);
                      this.updateErrorsFields();
                      return;
                    }
                    this.handleValueChange('partner', 'approved', !!!partner?.approved);
                  }} type={'radio'} />
                  <label className={'company-manage-checkbox-label'}>{translate('partner.edit.labels.approved.' + (partner?.approved ?? false))}</label>
                </div>
              </div>

              {resetKey && (
                <div className="partner-reset-password-container">
                  <h3>Reset de senha</h3>
                  {resetKey && (<p>{`URL Reset senha: `} <a target="_blank" style={{ color: 'blue' }} href={urlNewPassword}>{urlNewPassword}</a></p>)}
                  {resetDate && (<p>{`Data de expiração: ${moment(resetDate).format(APP_LOCAL_DATE_FORMAT)}`}</p>)}
                </div>
              )}

              <button disabled={this.state.requestingReset} onClick={this.handleInitNewPassword} className="button--reset__key">
                {this.state.requestingReset ? 'Solicitando...' : 'Solicitar novo reset key'}
              </button>

            </Col>
            <Col>
              <label className={'partner-header-label'}>
                <Translate contentKey="partner.register.labels.titlePartnerPlace" />
              </label>

              <div className={'partner-register-input-divisor-horizontal-container'}>
                <div className={'partner-register-input-divisor-horizontal-higher'}>
                  <InputField
                    title={translate('partner.register.labels.fantasyName')}
                    onChange={e => this.handleEventChange('partnerPlace', 'fantasyName', e)}
                    valid={this.state.valids?.['partnerPlace.fantasyName']}
                    error={this.state.errors?.['partnerPlace.fantasyName']}
                    inputClassName={'partner-register-two-inputs'}
                    value={partnerPlace?.fantasyName ?? ''}
                    placeholder={'partner.register.placeholders.fantasyName'}
                  />
                </div>

                <div className={'partner-register-input-divisor-horizontal-lower'}>
                  <InputField
                    onChange={this.handleIdentificationNumberChanged}
                    onKeyPress={this.handleIdentificationNumberOnKeyPressed}
                    onKeyDownPress={this.handleIdentificationNumberOnKeyDownPressed}
                    inputClassName={'partner-register-two-inputs'}
                    title={translate('partner.register.labels.identificationNumber')}
                    error={this.state.errors['identificationNumber']}
                    value={this.state.identificationNumber}
                    valid={this.state.valids['identificationNumber']}
                    placeholder={'partner.register.placeholders.identificationNumber'}
                    maskFormat={this.state.idNumberMask}
                    isMaskRequired
                  />
                </div>
              </div>

              <div className={'partner-register-input-divisor'}>
                <InputField
                  title={translate('partner.register.labels.socialReason')}
                  onChange={e => this.handleEventChange('partnerPlace', 'socialReason', e)}
                  valid={this.state.valids?.['partnerPlace.socialReason']}
                  error={this.state.errors?.['partnerPlace.socialReason']}
                  inputClassName={'partner-register-input'}
                  value={partnerPlace?.socialReason ?? ''}
                  placeholder={'partner.register.placeholders.socialReason'}
                  maxLength={512}
                />
              </div>

              <Row>
                <Col>
                  <div className={'partner-register-input-divisor'}>
                    <div className={'partner-register-input-divisor-cref'}>
                      <InputField
                        title={translate('partner.register.labels.cref')}
                        onChange={e => this.handleEventChange('partnerPlace', 'cref', e)}
                        valid={this.state.valids?.['partnerPlace.cref']}
                        error={this.state.errors?.['partnerPlace.cref']}
                        inputClassName={'partner-register-input-cref'}
                        value={partnerPlace?.cref ?? ''}
                        placeholder={'partner.register.placeholders.cref'}
                      />
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className={'partner-register-input-divisor'}>
                    <div className={'partner-register-input-divisor-cref'}>
                      <InputField
                        title={translate('partner.register.labels.crn')}
                        onChange={e => this.handleEventChange('partnerPlace', 'crn', e)}
                        valid={this.state.valids?.['partnerPlace.crn']}
                        error={this.state.errors?.['partnerPlace.crn']}
                        inputClassName={'partner-register-input-cref'}
                        value={partnerPlace?.crn ?? ''}
                        placeholder={'partner.register.placeholders.crn'}
                      />
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className={'partner-register-input-divisor'}>
                    <div className={'partner-register-input-divisor-cref'}>
                      <InputField
                        title={translate('partner.register.labels.crp')}
                        onChange={e => this.handleEventChange('partnerPlace', 'crp', e)}
                        valid={this.state.valids?.['partnerPlace.crp']}
                        error={this.state.errors?.['partnerPlace.crp']}
                        inputClassName={'partner-register-input-cref'}
                        value={partnerPlace?.crp ?? ''}
                        placeholder={'partner.register.placeholders.crp'}
                      />
                    </div>
                  </div>
                </Col>
              </Row>

              <div className={'partner-register-input-divisor'}>
                <label className={'partner-register-input-label'}>
                  Especialidades
                </label>
                <Dropdown isOpen={this.state.specialtiesDropdownOpen} toggle={this.toggleSpecialtiesDropdown} className="partner-register-dropdown" direction="down">
                  <DropdownToggle caret className="partner-register-dropdown-toggle">
                    {this.state.selectedSpecialties.length > 0
                      ? `${this.state.selectedSpecialties.length} especialidades selecionadas`
                      : 'Selecione as especialidades'}
                  </DropdownToggle>
                  <DropdownMenu className="partner-register-dropdown-menu" container="body">
                    {this.props.specialtiesByDocument?.length > 0 ? (
                      this.props.specialtiesByDocument.map(specialty => (
                        <DropdownItem
                          key={`specialty-${specialty.id}`}
                          onClick={() => this.handleSelectSpecialty(specialty)}
                          className={this.isSpecialtySelected(specialty.id) ? 'selected-specialty' : ''}
                        >
                          <div className="specialty-item">
                            <span>{specialty.specialtyName}</span>
                            {this.isSpecialtySelected(specialty.id) && (
                              <FontAwesomeIcon icon="check" className="specialty-check" />
                            )}
                          </div>
                        </DropdownItem>
                      ))
                    ) : (
                      <DropdownItem disabled>
                        {this.state.professionalCouncilDocumentId
                          ? 'Nenhuma especialidade disponível para este documento profissional'
                          : partnerPlace?.cref
                            ? 'Sem especialidades para registro CREF'
                            : partnerPlace?.crn
                              ? 'Sem especialidades para registro CRN'
                              : partnerPlace?.crp
                                ? 'Sem especialidades para registro CRP'
                                : partnerPlace?.crefito
                                  ? 'Sem especialidades para registro CREFITO'
                                  : 'Documento profissional não identificado'
                        }
                      </DropdownItem>
                    )}
                  </DropdownMenu>
                </Dropdown>
                {this.state.selectedSpecialties.length > 0 && (
                  <div className="selected-specialties-display">
                    {this.state.selectedSpecialties.map(specialty => (
                      <div key={`selected-specialty-${specialty.id}`} className="selected-specialty-tag">
                        {specialty.specialtyName}
                        <span
                          className="remove-specialty"
                          onClick={() => this.handleSelectSpecialty(specialty)}
                        >
                          ×
                        </span>
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <div className={'partner-register-input-divisor'}>
                <InputField
                  title={translate('partner.register.labels.description')}
                  onChange={e => this.handleEventChange('partnerPlace', 'description', e)}
                  valid={this.state.valids?.['partnerPlace.description']}
                  error={this.state.errors?.['partnerPlace.description']}
                  inputClassName={'partner-register-input'}
                  value={partnerPlace?.description ?? ''}
                  maxLength={4000}
                  isTextArea
                  placeholder={'partner.register.placeholders.description'}
                />
              </div>

              <div className={'partner-register-inner-addon'} onClick={this.handleOnAddressPressed}>
                <InputField
                  title={translate('partner.register.labels.address')}
                  inputClassName={'partner-register-search-input'}
                  valid={this.state.valids?.['partnerPlace.address']}
                  error={this.state.errors?.['partnerPlace.address']}
                  value={fullAddress ?? ''}
                  placeholder={'partner.register.placeholders.address'}
                  readOnly
                  onClick={this.handleOnAddressPressed}
                />
              </div>
              {this.state.errors?.['partnerPlace.address'] && (
                <div className={'partner-register-input-divisor'}>
                  <p className="marginT1">{`street: ${this.state.partnerPlace?.address?.street}`}</p>
                  <p className="marginT1">{`number: ${this.state.partnerPlace?.address?.number}`}</p>
                  <p className="marginT1">{`district: ${this.state.partnerPlace?.address?.district}`}</p>
                  <p className="marginT1">{`city: ${this.state.partnerPlace?.address?.city?.name}`}</p>
                  <p className="marginT1">{`city: ${this.state.partnerPlace?.address?.city?.state?.name}`}</p>
                  <p className="marginT1">{`zipcode: ${this.state.partnerPlace?.address?.zipcode}`}</p>
                  <p className="marginT1">{`latitude: ${this.state.partnerPlace?.address?.geoPoint?.latitude}`}</p>
                  <p className="marginT1">{`longitude: ${this.state.partnerPlace?.address?.geoPoint?.longitude}`}</p>
                </div>
              )}

              <Row>
                <Col>
                  <div className={'partner-register-input-divisor-horizontal-higher-without-label'}>
                    <InputField
                      title={translate('partner.register.labels.phone')}
                      onChange={e => this.handleOnPhoneChanged('number', e)}
                      error={this.state.errors['partnerPlace.phone.number']}
                      valid={this.state.valids['partnerPlace.phone.number']}
                      inputClassName={'partner-register-two-inputs'}
                      value={partnerPlace?.phone?.number?.replace?.(/\D/g, '') ?? ''}
                      placeholder={'partner.register.placeholders.number'}
                      maskFormat={'(99) 999999999'}
                      isMaskRequired
                    />
                  </div>
                </Col>

                <Col>
                  <div className={'partner-phone-input-divisor-selector'}>
                    <label className={'partner-register-input-label'}>
                      <Translate contentKey={'partner.register.labels.phoneType'} />
                    </label>
                    <select onChange={e => this.handleOnPhoneChanged('type', e)} className={'partner-register-select'}>
                      {Object.values(PhoneType).map((item, index) => (
                        <option value={item} key={index}>
                          {translate(`partner.register.options.${item}`)}
                        </option>
                      ))}
                    </select>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row style={{ marginTop: 70 }}>
            <Col>
              <input
                id={'myFileInput'}
                className={'partner-image-input'}
                onChange={this.handleImageChanged}
                accept={'image/*'}
                type={'file'}
              />
              {imageSrc != null ? (
                <>
                  <div style={{ marginTop: 20, height: 100 }}>
                    <img height={100} src={imageSrc} />
                  </div>
                  <div className={'partner-register-image-name'}>
                    <button onClick={() => document.getElementById('myFileInput').click()}>Alterar imagem</button>
                  </div>
                </>
              ) : (
                <div className={'partner-register-image-container'}>
                  <Button
                    onClick={() => document.getElementById('myFileInput').click()}
                    title={translate('editActivity.buttons.addImage')}
                    buttonLabelClassName={'partner-register-select-image-button-label'}
                    outerClassName={'partner-register-select-image-button-background-outer-class-name'}
                    innerClassName={'partner-register-select-image-button-background-inner-class-name'}
                  />
                </div>
              )}
            </Col>
            <Col>
              <input
                id={'myFileCoverImageInput'}
                className={'partner-image-input'}
                onChange={this.handleCoverImageChanged}
                accept={'image/*'}
                type={'file'}
              />
              {coverImageSrc != null ? (
                <>
                  <div style={{ marginTop: 20, height: 100 }}>
                    <img height={100} src={coverImageSrc} />
                  </div>
                  <div className={'partner-register-image-name'}>
                    <button onClick={() => document.getElementById('myFileCoverImageInput').click()}>Alterar imagem de capa</button>
                  </div>
                </>
              ) : (
                <div className={'partner-register-image-container'}>
                  <Button
                    onClick={() => document.getElementById('myFileCoverImageInput').click()}
                    title={translate('partner.register.labels.coverImage')}
                    buttonLabelClassName={'partner-register-select-image-button-label'}
                    outerClassName={'partner-register-select-image-button-background-outer-class-name'}
                    innerClassName={'partner-register-select-image-button-background-inner-class-name'}
                  />
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Button onClick={this.handleConfirmButtonPressed} title={translate('partner.register.buttons.save')} />
          </Row>
        </div>
        <p style={{ marginLeft: 30, textDecoration: 'underline' }} className={'partner-header-label'}>
          <a href={`/#/application-admin/partner-place/${partnerPlace?.id}/wallet`}>
            Ver carteira
          </a>
        </p>
        <BillingsTable partnerPlace={partnerPlace} partnerBillingAccountId={partnerBillingAccountId} />
      </>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  partnerPlace: storeState.partnerPlaces.partnerPlace as IPartnerPlace,
  saveAdminPartnerStatus: storeState.partners.saveAdminPartnerStatus,
  updateIndexStatus: storeState.partnerPlaces.updateIndexStatus,
  initNewPasswordStatus: storeState.user.initNewPasswordStatus,
  professionalCouncilDocuments: storeState.professionalCouncilDocument.professionalCouncilDocuments,
  specialtiesByDocument: storeState.specialty.specialtiesByDocument,
  specialties: storeState.specialty.specialties
});

const mapDispatchToProps = {
  getPartnerPlaceById,
  reset,
  updateReset,
  showModal,
  hideModal,
  updatePartner,
  fetchAddresses,
  forceUpdateIndexPartnerPlace,
  initNewPassword,
  resetNewPassword: userReduxReset,
  getProfessionalCouncilDocuments,
  getSpecialtiesByDocumentId,
  getSpecialties,
  clearSpecialtiesByDocument
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IPartnerPlaceManage);
