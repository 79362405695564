import React, { useState, useEffect } from 'react';
import { IRootState } from '../../../shared/reducers';
import { connect } from 'react-redux';
import {
  getProfessionalCouncilDocuments,
  createProfessionalCouncilDocument,
  updateProfessionalCouncilDocument,
  deleteProfessionalCouncilDocument,
  reset,
  ProfessionalCouncilDocumentDTO,
  CreateProfessionalCouncilDocumentDTO
} from '../../../entities/professional-council-document/professional-council-document-redux';
import { HttpRequestStatus } from '../../../shared/model/enum/HttpRequestStatus';
import { Table, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Badge, ButtonGroup } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TailSpin } from 'react-loader-spinner';
import { toast } from 'react-toastify';
import './styles.scss';

interface IProfessionalCouncilDocumentProps extends StateProps, DispatchProps {}

type StatusFilter = 'ALL' | 'ACTIVE' | 'INACTIVE';

const ProfessionalCouncilDocument = (props: IProfessionalCouncilDocumentProps) => {
  const {
    documents,
    getDocumentsStatus,
    createStatus,
    updateStatus,
    deleteStatus,
    getAllDocuments,
    createDocument,
    updateDocument,
    deleteDocument
  } = props;

  const [documentsList, setDocumentsList] = useState<ProfessionalCouncilDocumentDTO[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState<StatusFilter>('ALL');

  const [createModal, setCreateModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const [newDocument, setNewDocument] = useState<CreateProfessionalCouncilDocumentDTO>({ documentCode: '' });
  const [editDocument, setEditDocument] = useState<ProfessionalCouncilDocumentDTO>({ id: 0, documentCode: '', active: true });

  useEffect(() => {
    getAllDocuments();
  }, []);

  useEffect(() => {
    if (getDocumentsStatus === HttpRequestStatus.SUCCESS && documents) {
      setDocumentsList(documents);
      reset();
    }
  }, [getDocumentsStatus]);

  useEffect(() => {
    if (createStatus === HttpRequestStatus.SUCCESS) {
      toast.success('Documento criado com sucesso!');
      setCreateModal(false);
      setNewDocument({ documentCode: '' });
      getAllDocuments();
      reset();
    } else if (createStatus === HttpRequestStatus.ERROR) {
      toast.error('Erro ao criar documento.');
      reset();
    }
  }, [createStatus]);

  useEffect(() => {
    if (updateStatus === HttpRequestStatus.SUCCESS) {
      toast.success('Documento atualizado com sucesso!');
      setEditModal(false);
      getAllDocuments();
      reset();
    } else if (updateStatus === HttpRequestStatus.ERROR) {
      toast.error('Erro ao atualizar documento.');
      reset();
    }
  }, [updateStatus]);

  useEffect(() => {
    if (deleteStatus === HttpRequestStatus.SUCCESS) {
      toast.success('Documento excluído com sucesso!');
      setDeleteModal(false);
      getAllDocuments();
      reset();
    } else if (deleteStatus === HttpRequestStatus.ERROR) {
      toast.error('Erro ao excluir documento.');
      reset();
    }
  }, [deleteStatus]);

  const toggleCreateModal = () => setCreateModal(!createModal);
  const toggleEditModal = () => setEditModal(!editModal);
  const toggleDeleteModal = () => setDeleteModal(!deleteModal);

  const handleCreateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewDocument({ ...newDocument, [e.target.name]: e.target.value });
  };

  const handleEditChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setEditDocument({ ...editDocument, [e.target.name]: value });
  };

  const handleCreate = (e: React.FormEvent) => {
    e.preventDefault();
    createDocument(newDocument);
  };

  const handleUpdate = (e: React.FormEvent) => {
    e.preventDefault();
    if (editDocument.id) {
      updateDocument(editDocument.id, editDocument);
    }
  };

  const handleDelete = () => {
    if (editDocument.id) {
      deleteDocument(editDocument.id);
    }
  };

  const openEditModal = (document: ProfessionalCouncilDocumentDTO) => {
    setEditDocument(document);
    setEditModal(true);
  };

  const openDeleteModal = (document: ProfessionalCouncilDocumentDTO) => {
    setEditDocument(document);
    setDeleteModal(true);
  };

  const handleStatusFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setStatusFilter(e.target.value as StatusFilter);
  };

  const filteredDocuments = documentsList.filter(doc => {

    const matchesSearch = doc.documentCode.toLowerCase().includes(searchTerm.toLowerCase());

    let matchesStatus = true;
    if (statusFilter === 'ACTIVE') {
      matchesStatus = doc.active === true;
    } else if (statusFilter === 'INACTIVE') {
      matchesStatus = doc.active === false;
    }

    return matchesSearch && matchesStatus;
  });

  const getStatusCount = (status: StatusFilter) => {
    if (status === 'ALL') {
      return documentsList.length;
    } else if (status === 'ACTIVE') {
      return documentsList.filter(doc => doc.active === true).length;
    } else {
      return documentsList.filter(doc => doc.active === false).length;
    }
  };

  return (
    <div className="professional-council-document-container">
      <div className="header-section">
        <h2>Gerenciamento de Documentos de Conselho Profissional</h2>
        <div className="actions-container">
          <div className="search-container">
            <Input
              type="text"
              placeholder="Buscar por código do documento..."
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
              className="search-input"
            />
            <FontAwesomeIcon icon="search" className="search-icon" />
          </div>

          <div className="status-filter">
            <div className="select-wrapper">
              <FontAwesomeIcon icon="filter" className="filter-icon" />
              <select
                className="status-select"
                value={statusFilter}
                onChange={handleStatusFilterChange}
              >
                <option value="ALL">Todos ({getStatusCount('ALL')})</option>
                <option value="ACTIVE">Ativos ({getStatusCount('ACTIVE')})</option>
                <option value="INACTIVE">Inativos ({getStatusCount('INACTIVE')})</option>
              </select>
              <FontAwesomeIcon icon="chevron-down" className="chevron-icon" />
            </div>
          </div>

          <Button color="primary" onClick={toggleCreateModal} className="create-button">
            <FontAwesomeIcon icon="plus" /> Novo Documento
          </Button>
        </div>
      </div>

      {getDocumentsStatus === HttpRequestStatus.ONGOING && (
        <div className="loading-container">
          <TailSpin height="50" width="50" color="green" />
        </div>
      )}

      {getDocumentsStatus === HttpRequestStatus.SUCCESS && filteredDocuments.length === 0 ? (
        <div className="no-records">
          <FontAwesomeIcon icon="folder-open" size="3x" />
          <h4>Nenhum documento encontrado</h4>
          <p>Crie um novo documento ou ajuste seus critérios de busca.</p>
        </div>
      ) : (
        <Table className="documents-table" responsive hover>
          <thead>
            <tr>
              <th>ID</th>
              <th>Código do Documento</th>
              <th>Status</th>
              <th className="text-center">Ações</th>
            </tr>
          </thead>
          <tbody>
            {filteredDocuments.map((document, index) => (
              <tr key={index} className={index % 2 === 0 ? 'even-row' : 'odd-row'}>
                <td>{document.id}</td>
                <td>{document.documentCode}</td>
                <td>
                  {document.active ? (
                    <Badge color="success" pill>Ativo</Badge>
                  ) : (
                    <Badge color="secondary" pill>Inativo</Badge>
                  )}
                </td>
                <td className="text-center">
                  <Button color="info" size="sm" className="action-button" onClick={() => openEditModal(document)}>
                    <FontAwesomeIcon icon="edit" />
                  </Button>
                  <Button color="danger" size="sm" className="action-button" onClick={() => openDeleteModal(document)}>
                    <FontAwesomeIcon icon="trash" />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      <Modal isOpen={createModal} toggle={toggleCreateModal}>
        <ModalHeader toggle={toggleCreateModal}>Novo Documento de Conselho Profissional</ModalHeader>
        <Form onSubmit={handleCreate}>
          <ModalBody>
            <FormGroup>
              <Label for="documentCode">Código do Documento</Label>
              <Input
                type="text"
                name="documentCode"
                id="documentCode"
                value={newDocument.documentCode}
                onChange={handleCreateChange}
                placeholder="Digite o código do documento"
                required
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleCreateModal}>Cancelar</Button>
            <Button
              color="primary"
              type="submit"
              disabled={createStatus === HttpRequestStatus.ONGOING}
            >
              {createStatus === HttpRequestStatus.ONGOING ? (
                <span><TailSpin height="15" width="15" color="white" /> Salvando...</span>
              ) : 'Salvar'}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={editModal} toggle={toggleEditModal}>
        <ModalHeader toggle={toggleEditModal}>Editar Documento de Conselho Profissional</ModalHeader>
        <Form onSubmit={handleUpdate}>
          <ModalBody>
            <FormGroup>
              <Label for="documentCode">Código do Documento</Label>
              <Input
                type="text"
                name="documentCode"
                id="editDocumentCode"
                value={editDocument.documentCode}
                onChange={handleEditChange}
                placeholder="Digite o código do documento"
                required
              />
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  name="active"
                  checked={editDocument.active}
                  onChange={handleEditChange}
                />{' '}
                Ativo
              </Label>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleEditModal}>Cancelar</Button>
            <Button
              color="primary"
              type="submit"
              disabled={updateStatus === HttpRequestStatus.ONGOING}
            >
              {updateStatus === HttpRequestStatus.ONGOING ? (
                <span><TailSpin height="15" width="15" color="white" /> Atualizando...</span>
              ) : 'Atualizar'}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={deleteModal} toggle={toggleDeleteModal}>
        <ModalHeader toggle={toggleDeleteModal}>Confirmar Exclusão</ModalHeader>
        <ModalBody>
          <p>Você tem certeza que deseja excluir o documento <strong>{editDocument.documentCode}</strong>?</p>
          <p className="text-danger">Esta ação não pode ser desfeita.</p>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleDeleteModal}>Cancelar</Button>
          <Button
            color="danger"
            onClick={handleDelete}
            disabled={deleteStatus === HttpRequestStatus.ONGOING}
          >
            {deleteStatus === HttpRequestStatus.ONGOING ? (
              <span><TailSpin height="15" width="15" color="white" /> Excluindo...</span>
            ) : 'Excluir'}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  documents: storeState.professionalCouncilDocument.professionalCouncilDocuments,
  getDocumentsStatus: storeState.professionalCouncilDocument.getDocumentsStatus,
  createStatus: storeState.professionalCouncilDocument.createStatus,
  updateStatus: storeState.professionalCouncilDocument.updateStatus,
  deleteStatus: storeState.professionalCouncilDocument.deleteStatus
});

const mapDispatchToProps = {
  getAllDocuments: getProfessionalCouncilDocuments,
  createDocument: createProfessionalCouncilDocument,
  updateDocument: updateProfessionalCouncilDocument,
  deleteDocument: deleteProfessionalCouncilDocument,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfessionalCouncilDocument);
