import React from 'react';
import { connect } from 'react-redux';
import { IRootState } from '../../../shared/reducers';
import { getPartnersPayment, postPartnerPlacePayment } from '../../../entities/partner-place/partner-place-redux';
import { HttpRequestStatus } from '../../../shared/model/enum/HttpRequestStatus';
import { TailSpin } from 'react-loader-spinner';
import { Table } from 'reactstrap';
import PaymentModal, { PaymentModalInfo } from './components/paymentModal';
import StringUtils from '../../../util/StringUtils';
import { getWithdrawsTransactions } from '../../../entities/keiken-transaction/keiken-transaction';
import { JhiPagination } from 'react-jhipster';
import { Row } from 'reactstrap';
import './styles.scss';
import { faCalendarCheck, faEdit, faMoneyBillAlt, faAddressBook } from '@fortawesome/fontawesome-free-regular';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { Label, Input } from 'reactstrap';
import { IPartnerPlacePaymentInfo } from '../../../shared/model/partner-place.model';

export interface PartnerPlacePaymentContentInfo {
  partnerPlace: {
    id: number,
    fantasyName: string
  };
  currentBalance: number;
  pixKey: string;
  billingAccountId: number;
  keikenTax: number;
}

interface IPartnersPaymentProps extends StateProps, DispatchProps { }

const partnersPayment = ({
  getPartnersPaymentProps,
  partnersPaymentStatus,
  postPartnerPlacePaymentProps,
  withdrawsTransactions,
  getWithdrawsTransactionsProps,
  partnersPaymentContent,
  postPartnerPlacePaymentStatus
}: IPartnersPaymentProps) => {
  const [page, setPage] = React.useState(1);
  const [paymentModal, setPaymentModal] = React.useState<PaymentModalInfo>({
    open: false
  });
  const [monthYear, setMonthYear] = React.useState<Date>(new Date());
  const [partnersSelected, setPartnersSelected] = React.useState<number[]>([]);
  const [selectMode, setSelectMode] = React.useState<boolean>(false);

  const monthYearDateTime = monthYear.toISOString();

  React.useEffect(() => {
    getPartnersPaymentProps(page - 1, monthYearDateTime);
  }, [page, monthYear]);

  React.useEffect(() => {
    getWithdrawsTransactionsProps(monthYearDateTime);
  }, [monthYearDateTime]);

  const postStatus = postPartnerPlacePaymentStatus;
  React.useEffect(() => {
    if (postStatus === HttpRequestStatus.SUCCESS) {
      setPartnersSelected([]);
      getWithdrawsTransactionsProps(monthYearDateTime);
      toast.success('Pagamento efetuado com sucesso!');
    } else if (postStatus === HttpRequestStatus.ERROR) {
      toast.error('Algo deu errado ao pagar parceiro!');
    }
    if (postStatus === HttpRequestStatus.SUCCESS || postStatus === HttpRequestStatus.ERROR) {
      setTimeout(() => {
        getPartnersPaymentProps(page - 1, monthYearDateTime);
        getWithdrawsTransactionsProps(monthYearDateTime);
      }, 800);
    }

  }, [postStatus]);

  const handleSelectPartnerToPay = (id: number, name: string, value: number, pixKey: string) => {
    setPaymentModal({
      open: true,
      partnerPlace: {
        id,
        name,
        value,
        pixKey
      }
    });
  };

  const handlePagination = (activePage: number) => {
    setPage(activePage);
  };

  const handleOnChangeMonth = (action: 'NEXT' | 'BACK') => {
    const newMonthYear = new Date(monthYear);
    if (action === 'NEXT') {
      newMonthYear.setMonth(newMonthYear.getMonth() + 1);
    } else {
      newMonthYear.setMonth(newMonthYear.getMonth() - 1);
    }

    setMonthYear(newMonthYear);
    setPage(1);
  };

  const partnersBillingAccountIdsAlreadyPaidThisMonth = withdrawsTransactions?.map(withdraw => withdraw.billing?.partnerPlaceBillingAccount?.id);
  const isThisPartnerAlreadyPaidThisMonth = (partnerBillingAccountId: number) => {
    if (partnersBillingAccountIdsAlreadyPaidThisMonth?.includes(partnerBillingAccountId)) {
      return true;
    }

    return false;
  };

  const isSeenHistory: () => boolean = () => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    const selectedMonth = monthYear.getMonth();
    const selectedYear = monthYear.getFullYear();

    if (selectedYear < currentYear) {
      return true;
    }

    if (selectedYear === currentYear && selectedMonth < currentMonth) {
      return true;
    }

    return false;
  };

  const getTransactionValue = (partnerBillingAccountId: number) => {
    const transaction = withdrawsTransactions?.find(withdraw => withdraw.billing?.partnerPlaceBillingAccount?.id === partnerBillingAccountId);
    if (!transaction) {
      return 0;
    }
    return transaction.value;
  };

  const selectPartner = (partnerId: number) => {
    // se o modo de seleção estiver ativo, então seleciona o parceiro
    const doesntHavePixKey = partnersPaymentContent?.content.find(partner => partner.partnerPlace.id === partnerId)?.pixKey?.length === undefined;
    const alreadyPaidOrDoesntHavePixKey = isThisPartnerAlreadyPaidThisMonth(partnersPaymentContent?.content.find(partner => partner.partnerPlace.id === partnerId)?.billingAccountId) || doesntHavePixKey;
    if (selectMode && !alreadyPaidOrDoesntHavePixKey) {
      if (partnersSelected.includes(partnerId)) {
        setPartnersSelected(partnersSelected.filter(partner => partner !== partnerId));
      } else {
        setPartnersSelected([...partnersSelected, partnerId]);
      }
    }
  };

  const handlePaySelectedPartners = () => {
    const partnersToPay = partnersPaymentContent?.content.filter(partner => partnersSelected.includes(partner.partnerPlace.id));

    const partnerPaymentsDTO: IPartnerPlacePaymentInfo[] = partnersToPay.map(partner => ({
      id: partner.partnerPlace.id,
      value: partner.currentBalance,
      pixKey: partner.pixKey,
      shouldPay: true
    }));

    postPartnerPlacePaymentProps(partnerPaymentsDTO);
  };

  const selectPartnerAllButAlreadyPaidAndWithoutPixKey = () => {
    const partnersToSelect = partnersPaymentContent?.content.filter(partner => !isThisPartnerAlreadyPaidThisMonth(partner.billingAccountId) && partner.pixKey?.length > 0);
    const partnersIds = partnersToSelect.map(partner => partner.partnerPlace.id);
    setPartnersSelected(partnersIds);
  };

  const monthWritten = monthYear.toLocaleString('pt-BR', { month: 'long' });
  const monthWIthFirstLetterUppercase = monthWritten.charAt(0).toUpperCase() + monthWritten.slice(1);
  const yearWritten = monthYear.getFullYear();

  const totalComissions = partnersPaymentContent?.content.reduce((acc, partnerPayment) => acc + partnerPayment.keikenTax, 0);
  const totalPartnerProfit = partnersPaymentContent?.content.reduce((acc, partnerPayment) => acc + (isThisPartnerAlreadyPaidThisMonth(partnerPayment.billingAccountId) ? getTransactionValue(partnerPayment.billingAccountId) : partnerPayment.currentBalance), 0);
  const totalSold = partnersPaymentContent?.content.reduce((acc, partnerPayment) => acc + (isThisPartnerAlreadyPaidThisMonth(partnerPayment.billingAccountId) ? getTransactionValue(partnerPayment.billingAccountId) : partnerPayment.currentBalance) + partnerPayment.keikenTax, 0);
  return (
    <>
      <div className="genereal--info--and--date--time--container">

        <div className="partners--payment__date__info">
          <div className="arrow--item__left" onClick={() => handleOnChangeMonth('BACK')}>
            <FontAwesomeIcon color="black" icon={'arrow-left'} />
          </div>

          <h3 className="month--year--to--search">{monthWIthFirstLetterUppercase + ' - ' + yearWritten}</h3>

          <div className="arrow--item__right" onClick={() => handleOnChangeMonth('NEXT')}>
            <FontAwesomeIcon color="black" icon={'arrow-right'} />
          </div>
        </div>

        <div className="general--info">
          <span className="totals--info">
            Total Comissão Keiken:
            <span>
              {StringUtils.currencyPtBr(totalComissions)}
            </span>
          </span>

          <span className="totals--info">
            Total Repasses:
            <span>
              {StringUtils.currencyPtBr(totalPartnerProfit)}
            </span>
          </span>

          <span className="totals--info">
            Total Vendido:
            <span>
              {StringUtils.currencyPtBr(totalSold)}
            </span>

          </span>
        </div>

        {/* chechbox modo de seleção */}
        <div className="checkbox-container select--mode--container">
          <Label className="checkbox-label" check>
            <Input
              className="checkbox-input"
              type="checkbox"
              checked={selectMode}
              onChange={() => setSelectMode(!selectMode)}
            />{' '}
            Modo de seleção
          </Label>
        </div>

        {/* botões de selecionar todos e deselecionar todos */}
        <div className="select--all--partners--buttons">
          <button
            className="btn btn-secondary--partners-payment"
            onClick={() => {
              setPartnersSelected([]);
            }}
          >
            {'Desselecionar Todos'}
          </button>

          <button
            className="btn btn-primary--partners-payment"
            onClick={() => selectPartnerAllButAlreadyPaidAndWithoutPixKey()}
          >
            {'Selecionar Todos'}
          </button>
        </div>

        {/* botão verde pagar todos selecionados */}
        <div className="pay--all--selected--button--container">
          <button
            className="btn btn-success--partners-payment"
            onClick={() => handlePaySelectedPartners()}
          >
            {'Pagar Selecionados'}
          </button>
        </div>
      </div>

      {partnersPaymentStatus === HttpRequestStatus.ONGOING && (
        <div className="loading-container">
          <TailSpin height="50" width="50" color="green" />
        </div>
      )}

      <Table className="partners--payment__table">
        <thead>
          <tr>
            <th>{'Nome Parceiro'}</th>
            <th>{'Chave Pix Cadastrada'}</th>
            <th>{'Comissão Keiken'}</th>
            <th>{'Repasse'}</th>
            <th>{'TPV'}</th>
            <th>{'Pagar'}</th>
          </tr>
        </thead>

        <tbody>
          {partnersPaymentContent?.content.length > 0 && partnersPaymentContent?.content?.map((partnerPayment: PartnerPlacePaymentContentInfo, index) => {
            const isAlreadyPaid = isThisPartnerAlreadyPaidThisMonth(partnerPayment.billingAccountId);
            const withDrawTransaction = withdrawsTransactions?.find(withdraw => withdraw.billing?.partnerPlaceBillingAccount?.id === partnerPayment.billingAccountId);
            const isPartnerWithPixKey = partnerPayment?.pixKey?.length > 0;
            const getTransactionValueFormatted = StringUtils.currencyPtBr(getTransactionValue(partnerPayment?.billingAccountId));

            const totalValueWithTransaction = getTransactionValue(partnerPayment?.billingAccountId) + partnerPayment?.keikenTax;
            const totalValue = partnerPayment?.currentBalance + partnerPayment?.keikenTax;
            const totalValueReal = isAlreadyPaid ? totalValueWithTransaction : totalValue;

            const isPartnerSelected = partnersSelected.includes(partnerPayment.partnerPlace.id);

            return (
              <tr
                key={index}
                style={{ backgroundColor: isPartnerSelected ? '#25c51f' : 'white', cursor: selectMode ? 'pointer' : 'default' }}
                onClick={() => selectPartner(partnerPayment?.partnerPlace?.id)}
              >
                <td style={{ textAlign: 'initial' }}>{partnerPayment?.partnerPlace?.fantasyName}</td>
                <td style={{ textAlign: 'initial' }}>{isPartnerWithPixKey ? partnerPayment?.pixKey : <span style={{ color: 'red' }}>Indisponível</span>}</td>
                <td>{StringUtils.currencyPtBr(partnerPayment?.keikenTax)}</td>
                <td>{isAlreadyPaid ? getTransactionValueFormatted : StringUtils.currencyPtBr(partnerPayment.currentBalance)}</td>
                <td>{StringUtils.currencyPtBr(totalValueReal)}</td>
                <td>
                  <button
                    style={{ minWidth: '110px' }}
                    className={withDrawTransaction?.status === 'WAITING_INTER_APPROVAL' ? 'btn btn-waiting' : withDrawTransaction?.status === 'PAID' ? 'btn btn-paid' : 'btn btn-primary'}
                    disabled={isAlreadyPaid || isSeenHistory()}
                    onClick={() => handleSelectPartnerToPay(
                      partnerPayment.partnerPlace?.id,
                      partnerPayment.partnerPlace?.fantasyName,
                      partnerPayment.currentBalance,
                      partnerPayment?.pixKey
                    )}
                  >
                    {(isAlreadyPaid || isSeenHistory()) ? withDrawTransaction?.status === 'WAITING_INTER_APPROVAL' ? 'Aguardando' : 'Pago' : 'Pagar'}
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>

      </Table >
      {/* {partnersPaymentContent?.content?.length > 0 && (
        <Row className={'justify-content-center'}>
          <JhiPagination
            items={
              partnersPaymentContent?.totalPages
            }
            activePage={page}
            onSelect={number => handlePagination(number)}
            maxButtons={10}
          />
        </Row>
      )} */}

      <PaymentModal
        paymentModal={paymentModal}
        setPartnersPaymentModal={setPaymentModal}
      />
    </>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  partnersPaymentContent: storeState.partnerPlaces.partnersPayment,
  partnersPaymentStatus: storeState.partnerPlaces.getPartnersPaymentStatus,
  postPartnerPlacePaymentStatus: storeState.partnerPlaces.postPartnerPlacePaymentStatus,
  withdrawsTransactions: storeState.keikenTransaction.withdrawsTransactions
});

const mapDispatchToProps = {
  getPartnersPaymentProps: getPartnersPayment,
  getWithdrawsTransactionsProps: getWithdrawsTransactions,
  postPartnerPlacePaymentProps: postPartnerPlacePayment
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(partnersPayment);
