import React from 'react';

import { IPage } from '../../../../../../shared/model/page.model';
import { KeikenPayInvoice } from '../../../../../../shared/model/KeikenPay/KeikenPayInvoice';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import moment from 'moment';

import './history.scss';

interface HistoryProps {
  keikenPayPage: IPage<KeikenPayInvoice>;
}

export default function History({ keikenPayPage }: HistoryProps) {
  const { content } = keikenPayPage;

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [currentImages, setCurrentImages] = React.useState([]);
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [zoomLevel, setZoomLevel] = React.useState(1);
  const [mousePosition, setMousePosition] = React.useState({ x: 0.5, y: 0.5 });

  const toggleZoom = e => {
    // Calcula a posição do clique em relação à imagem
    const { left, top, width, height } = e.target.getBoundingClientRect();
    const x = (e.clientX - left) / width;
    const y = (e.clientY - top) / height;

    // Atualiza a posição do mouse para onde foi clicado
    setMousePosition({ x, y });

    // Altera o nível de zoom
    setZoomLevel(prevZoom => (prevZoom % 2) + 1);
    if (zoomLevel === 2) {
      setMousePosition({ x: 0.5, y: 0.5 });
    }
  };

  const openModal = images => {
    setCurrentImages(images);
    setCurrentIndex(0);
    setZoomLevel(1);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentImages([]);
    setCurrentIndex(0);
    setZoomLevel(1);
  };

  const showNextImage = () => {
    setCurrentIndex(prevIndex => (prevIndex + 1) % currentImages.length);
    setZoomLevel(1);
    setMousePosition({ x: 0.5, y: 0.5 });
  };

  const showPrevImage = () => {
    setCurrentIndex(prevIndex => (prevIndex - 1 + currentImages.length) % currentImages.length);
    setZoomLevel(1);
    setMousePosition({ x: 0.5, y: 0.5 });
  };

  return (
    <div className="history__container">
      <table className="history__table">
        <thead>
          <tr>
            <th>Data Requisitado</th>
            <th>Status</th>
            <th>Fotos</th>
            <th>Ver todas fotos</th>
          </tr>
        </thead>
        <tbody>
          {content?.map((keikenPayInvoice: KeikenPayInvoice) => {
            const allAuxImages = keikenPayInvoice?.auxUserImages?.map(image => image.imageUrl) ?? [];
            const allImages = [keikenPayInvoice?.image?.imageUrl, ...allAuxImages];

            return (
              <tr key={keikenPayInvoice.id}>
                <td>{moment(keikenPayInvoice?.billingCoParticipation?.date).format('YYYY-MM-DD HH:mm:ss')}</td>
                <td>{keikenPayInvoice?.status}</td>
                <td>{(keikenPayInvoice?.auxUserImages?.length ?? 0) + 1}</td>
                <td>
                  <Button color="primary" onClick={() => openModal(allImages)}>Ver todas fotos</Button>
                </td>
              </tr>
            );
          }
          )}
        </tbody>
      </table>

      <Modal isOpen={isModalOpen} toggle={closeModal} size="lg" centered className="image-modal">
        <ModalHeader toggle={closeModal}>Visualizador de Fotos</ModalHeader>
        <ModalBody className="text-center position-relative">
          {currentImages?.length > 0 && (
            <div
              className="image-container"
              onClick={toggleZoom}
              style={{
                cursor: zoomLevel === 2 ? 'zoom-out' : 'zoom-in',
                overflow: 'hidden'
              }}
            >
              <img
                src={currentImages[currentIndex]}
                alt={`Imagem ${currentIndex + 1}`}
                style={{
                  maxWidth: '100%',
                  maxHeight: '70vh',
                  transform: `scale(${zoomLevel == 1 ? 1 : 2.5}) translate(${(0.5 - mousePosition.x) * 100}%, ${(0.5 - mousePosition.y) * 100}%)`,
                  transition: zoomLevel === 1 ? 'transform 0.3s ease' : 'none'
                }}
              />
            </div>
          )}
          {currentImages.length > 1 && (
            <>
              {currentIndex > 0 && (
                <div className="arrow left-arrow" onClick={showPrevImage}>&lt;</div>
              )}
              {currentIndex < currentImages.length - 1 && (
                <div className="arrow right-arrow" onClick={showNextImage}>&gt;</div>
              )}
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <a href={currentImages[currentIndex]} download>
            <Button color="success">Baixar imagem</Button>
          </a>
          <Button color="danger" onClick={closeModal}>Fechar</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
