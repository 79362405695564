import { IActivity } from 'app/shared/model/activity';
import { IActivityCategory } from 'app/shared/model/activity-category';
import { IActivitySchedule } from 'app/shared/model/activity-schedule/activity-schedule';
import { IActivitySubCategory } from 'app/shared/model/activity-sub-category';
import { IPriceComposite } from 'app/shared/model/billing/price-composite';
import { IBillingCoParticipation } from './billing-co-participation';
import { ActivityPlan } from '../activity-plan';
import { ICustomer } from '../customer/customer';
import { IKeikenTransaction } from './keiken-transactions';
import { PresetPaymentMethodsToCharge } from '../preset-payment-methods-to-charge';
import { IPresetRenewalActivityPlan } from 'app/shared/model/billing/preset-renewal-activity-plan';

export enum BillingStatus {
  CREATED = 'CREATED',
  WAITING = 'WAITING',
  WAITING_INTER_APPROVAL = 'WAITING_INTER_APPROVAL',
  PAID = 'PAID',
  NOT_PAID = 'NOT_PAID',
  REVERTED = 'REVERTED',
  FUTURE_CHARGE = 'FUTURE_CHARGE',
  WAITING_COMPANY_PAY = 'WAITING_COMPANY_PAY'
}

export enum BillingCompanyPaymentMethod {
  NONE = 'NONE',
  BANK_SLIP = 'BANK_SLIP',
  PIX = 'PIX'
}

export interface BillingCompany {
  transactionDate: string;
  referenceDate: string;
  scheduledDate: string;
  paymentMethod: BillingCompanyPaymentMethod;
}

export interface IBilling {
  id?: number;
  date?: string;
  customer?: ICustomer;
  paymentMethod?: string; // todo: enum
  paymentProviderId?: string;
  value?: number;
  discountAmount?: number;
  keikenTax?: number;
  partnerProfit?: number;
  status?: BillingStatus;
  activitySchedules?: IActivitySchedule[];
  activityPlan?: ActivityPlan;
  billingCoParticipation?: IBillingCoParticipation;
  keikenTransactions?: IKeikenTransaction[];
  description?: string;
  billingCompany?: BillingCompany;
}

export interface IBillingCreateVM {
  billing?: IBilling;
  activityCategory?: IActivityCategory;
  activitySubCategory?: IActivitySubCategory;
  presetRenewalActivitySchedule?: IPresetRenewalActivitySchedule;
  presetPaymentMethodsToCharge?: PresetPaymentMethodsToCharge;
  priceComposite?: IPriceComposite;
  withoutPayment?: boolean;
  presetRenewalActivityPlan?: IPresetRenewalActivityPlan;
}

export interface IPresetRenewalActivitySchedule {
  id?: number;
  intervalWeek: number;
  activity: IActivity;
  activityCategory?: IActivityCategory;
  activitySubCategory?: IActivitySubCategory;
}
