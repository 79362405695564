import React from 'react';
import { Translate, translate } from 'react-jhipster';
import { Navbar, Nav, DropdownItem, NavItem, Collapse, Container, Badge } from 'reactstrap';
import LoadingBar from 'react-redux-loading-bar';
import { Brand, NavDropdown } from '../header/header-components';
import { RouteComponentProps } from 'react-router';
import { withRouter, Link } from 'react-router-dom';
import './header-application.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faGraduationCap,
  faList,
  faStar,
  faSignOutAlt,
  faCalendarCheck,
  faEdit,
  faMoneyBillAlt,
  faAddressBook,
  faReceipt,
  faFileInvoice,
  faCertificate,
  faIdCard
} from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export interface IHeaderApplicationAdminProps extends StateProps, DispatchProps, RouteComponentProps<{}> { }

export interface IHeaderApplicationAdminState {
  menuOpen: boolean;
}

export class HeaderApplicationAdmin extends React.Component<IHeaderApplicationAdminProps, IHeaderApplicationAdminState> {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false
    };
  }

  private renderAdminMenuSection = (title: string) => {
    return (
      <div className="dropdown-divider-with-title">
        <span>{title}</span>
      </div>
    );
  };

  private renderApplicationAdminMenus = () => {
    return (
      <div className="admin-menu-container">
        {this.renderAdminMenuSection(translate('application-admin.header.menuSections.management'))}

        <DropdownItem tag={Link} to={'/application-admin/customers'}>
          <FontAwesomeIcon icon={faAddressBook as IconProp} />{' '}
          <Translate contentKey={'application-admin.header.menuItems.customers'}>Customer List</Translate>
        </DropdownItem>
        <DropdownItem tag={Link} to={'/application-admin/companies-list'}>
          <FontAwesomeIcon icon={'building'} />
          <Translate contentKey={'application-admin.header.menuItems.companies'}>Companies</Translate>
        </DropdownItem>
        <DropdownItem tag={Link} to={'/application-admin/partner-place-list'}>
          <FontAwesomeIcon icon={'handshake'} />
          <Translate contentKey={'application-admin.header.menuItems.partners'}>Partners</Translate>
        </DropdownItem>

        {this.renderAdminMenuSection(translate('application-admin.header.menuSections.activities'))}

        <DropdownItem tag={Link} to={'/application-admin/activities-manage'}>
          <FontAwesomeIcon icon={faList as IconProp} />{' '}
          <Translate contentKey={'application-admin.header.menuItems.activities'}>Atividades aprovadas</Translate>
        </DropdownItem>
        <DropdownItem tag={Link} to={'/application-admin/approve-activities'}>
          <FontAwesomeIcon icon={'check'} />{' '}
          <Translate contentKey={'application-admin.header.menuItems.approveActivities'}>Approve Activities</Translate>
        </DropdownItem>
        <DropdownItem tag={Link} to={'/application-admin/approve-customer-activities'}>
          <FontAwesomeIcon icon={'check'} />{' '}
          <Translate contentKey={'application-admin.header.menuItems.approveCustomerActivities'}>Approve Activities</Translate>
        </DropdownItem>
        <DropdownItem tag={Link} to={'/application-admin/approved-customer-activities'}>
          <FontAwesomeIcon icon={'check'} />{' '}
          <Translate contentKey={'application-admin.header.menuItems.approvedCustomerActivities'}>Approve Activities</Translate>
        </DropdownItem>
        <DropdownItem tag={Link} to={'/application-admin/categories-list'}>
          <FontAwesomeIcon icon={'book'} />{' '}
          <Translate contentKey={'application-admin.header.menuItems.categoriesList'}>Category List</Translate>
        </DropdownItem>
        <DropdownItem tag={Link} to={'/application-admin/subcategories-list'}>
          <FontAwesomeIcon icon={'book'} />{' '}
          <Translate contentKey={'application-admin.header.menuItems.subcategoriesList'}>SubCategory List</Translate>
        </DropdownItem>

        {this.renderAdminMenuSection(translate('application-admin.header.menuSections.finance'))}

        <DropdownItem tag={Link} to={'/application-admin/price-table'}>
          <FontAwesomeIcon icon={faMoneyBillAlt as IconProp} />{' '}
          <Translate contentKey={'application-admin.header.menuItems.priceTable'}>Price Table list</Translate>
        </DropdownItem>
        <DropdownItem tag={Link} to={'/application-admin/billings'}>
          <FontAwesomeIcon icon={faMoneyBillAlt as IconProp} />{' '}
          <Translate contentKey={'application-admin.header.menuItems.billings'}>Billings List</Translate>
        </DropdownItem>
        <DropdownItem tag={Link} to={'/application-admin/keiken-pay-invoice'}>
          <FontAwesomeIcon icon={faFileInvoice} />{' '}
          <Translate contentKey={'application-admin.header.menuItems.keikenPay'}>Keiken Pay list</Translate>
        </DropdownItem>
        <DropdownItem tag={Link} to={'/application-admin/keiken-pay-partner-external'}>
          <FontAwesomeIcon icon={'exchange-alt'} />{' '}
          <Translate contentKey={'application-admin.header.menuItems.keikenPayPartnerExternal'}>Partner External</Translate>
        </DropdownItem>
        <DropdownItem tag={Link} to={'/application-admin/partnersPayment'}>
          <FontAwesomeIcon icon={'money-bill'} />{' '}
          <Translate contentKey={'application-admin.header.menuItems.partnersPayment'}>Repasse Parceiros</Translate>
        </DropdownItem>
        <DropdownItem tag={Link} to={'/application-admin/withdrawNF'}>
          <FontAwesomeIcon icon={faReceipt} />{' '}
          <Translate contentKey={'application-admin.header.menuItems.withdrawNF'}>Emissão Repasse NF</Translate>
        </DropdownItem>
        <DropdownItem tag={Link} to={'/application-admin/companiesCharges'}>
          <FontAwesomeIcon icon={faFileInvoice} />{' '}
          <Translate contentKey={'application-admin.header.menuItems.companiesCharges'}>Cobrança Empresas</Translate>
        </DropdownItem>

        {this.renderAdminMenuSection(translate('application-admin.header.menuSections.scheduling'))}

        <DropdownItem tag={Link} to={'/application-admin/schedule-manage'}>
          <FontAwesomeIcon icon={faCalendarCheck as IconProp} />{' '}
          <Translate contentKey={'application-admin.header.menuItems.scheduleManage'}>Schedule Manage</Translate>
        </DropdownItem>
        <DropdownItem tag={Link} to={'/application-admin/schedule-manage/edit'}>
          <FontAwesomeIcon icon={faEdit as IconProp} />{' '}
          <Translate contentKey={'application-admin.header.menuItems.editScheduleManage'}>Edit Schedule</Translate>
        </DropdownItem>
        <DropdownItem tag={Link} to={'/application-admin/renewal-activity-schedule'}>
          <FontAwesomeIcon icon={faCalendarCheck as IconProp} />{' '}
          <Translate contentKey={'application-admin.header.menuItems.renewalActivitySchedule'}>Renewal List</Translate>
        </DropdownItem>
        <DropdownItem tag={Link} to={'/application-admin/conferences'}>
          <FontAwesomeIcon icon={'video'} />{' '}
          <Translate contentKey={'application-admin.header.menuItems.conferences'}>Conferências</Translate>
        </DropdownItem>

        {this.renderAdminMenuSection(translate('application-admin.header.menuSections.professional'))}

        <DropdownItem tag={Link} to={'/application-admin/professional-council-document'}>
          <FontAwesomeIcon icon={faIdCard as IconProp} />{' '}
          <Translate contentKey={'application-admin.header.menuItems.professionalCouncilDocument'}>Documentos do Conselho Profissional</Translate>
          <Badge color="primary" pill className="menu-badge">Novo</Badge>
        </DropdownItem>
        <DropdownItem tag={Link} to={'/application-admin/specialty'}>
          <FontAwesomeIcon icon={faGraduationCap as IconProp} />{' '}
          <Translate contentKey={'application-admin.header.menuItems.specialty'}>Especialidades</Translate>
          <Badge color="primary" pill className="menu-badge">Novo</Badge>
        </DropdownItem>

        {this.renderAdminMenuSection(translate('application-admin.header.menuSections.other'))}

        <DropdownItem tag={Link} to={'/application-admin/faq-list'}>
          <FontAwesomeIcon icon={'question-circle'} />
          <Translate contentKey={'application-admin.header.menuItems.faqs'}>FAQs</Translate>
        </DropdownItem>
        <DropdownItem tag={Link} to={'/application-admin/activity-plan'}>
          <FontAwesomeIcon icon={'clipboard-list'} />{' '}
          <Translate contentKey={'application-admin.header.menuItems.activityPlan'}>Activity Plan list</Translate>
        </DropdownItem>
        <DropdownItem tag={Link} to={'/application-admin/assessments'}>
          <FontAwesomeIcon icon={faStar as IconProp} />{' '}
          <Translate contentKey={'application-admin.header.menuItems.assessments'}>Assessments list</Translate>
        </DropdownItem>
      </div>
    );
  };

  private handleLogout = () => {
    this.props.history.replace('/logout');
  };

  render() {
    return (
      <div id="app-header">
        <LoadingBar className="loading-bar" />
        <Navbar light expand="md" fixed="top" className="jh-navbar">
          <Container fluid>
            <Brand />

            <Collapse navbar>
              <Nav id="header-tabs" className="ml-auto-header" navbar>
                <div className="app-menu">
                  <NavDropdown
                    icon="th-large"
                    name={translate('application-admin.header.labels.menu')}
                    id="admin-menu"
                    style={{ maxHeight: '80vh', overflowY: 'auto' }}
                  >
                    {this.renderApplicationAdminMenus()}
                  </NavDropdown>
                </div>

                <div className="logout-container">
                  <NavItem>
                    <span className="application-logout-label" onClick={this.handleLogout}>
                      <FontAwesomeIcon icon={faSignOutAlt} className="logout-icon" />
                      <span>
                        <Translate contentKey="application-admin.header.labels.logout">Sair</Translate>
                      </span>
                    </span>
                  </NavItem>
                </div>
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </div>
    );
  }
}

const mapStateToProps = storeState => ({});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(HeaderApplicationAdmin);
