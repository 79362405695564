import React, { useEffect, useState } from 'react';
import './details.scss';
import { Requested } from './components/requested';
import { Subsidy } from './components/subsidy';
import { Client } from './components/client';
import { PartnerExternal } from './components/partnerExternal';
import { Receipt } from './components/receipt';
import { KeikenPayInvoice, KeikenPayInvoiceStatus } from '../../../../shared/model/KeikenPay/KeikenPayInvoice';
import { getKeikenPayInvoices, reset as resetKeikenPay } from '../../../../../app/entities/keiken-pay/keiken-pay-reducer';
import { formatDate } from '../../../../shared/util/date-utils';
import { translate } from 'react-jhipster';
import { IImage } from '../../../../shared/model/image';
import { saveAs } from 'file-saver';
import History from './components/History/history';
import { connect } from 'react-redux';
import { IRootState } from '../../../../shared/reducers';

interface Props extends StateProps, DispatchProps {
    data: KeikenPayInvoice;
    concatenedImgs: IImage[];
    handleNewImg: (newImage: IImage, type: 'MAIN' | 'AUX' | 'USERAUX', replaceIdx?: number | undefined) => void;
    onClickAccept: () => void;
    onClickReject: () => void;
    onClickAnalysis: () => void;
    updating: boolean;
}

export const Details = ({ data, concatenedImgs, handleNewImg, onClickAccept, onClickAnalysis, onClickReject, updating, getKeikenPayInvoicesProps, keikenPayPage}: Props) => {
    const [viewInformation, setViewInformation] = useState('solicitado');
    const [disabledAccept, setDisabledAccept] = useState(false);
    const [disabledInAnalysis, setDisabledInAnalysis] = useState(false);
    const [disabledReject, setDisabledReject] = useState(false);

    useEffect(() => {
        if (data?.customer?.id) {
            getKeikenPayInvoicesProps({
                'customerId': data?.customer?.id,
                size: 20
            });
        }
    }, [data]);

    const download = async () => {
        const originalImage = data.image?.imageUrl ?? '';
        saveAs(originalImage, 'recibo.png');
    };

    useEffect(() => {
        if (updating === true || data.status === KeikenPayInvoiceStatus.DENIED || data.status === KeikenPayInvoiceStatus.PAID) {
            setDisabledAccept(true);
        }

        if (updating === true || data.status === KeikenPayInvoiceStatus.DENIED || data.status === KeikenPayInvoiceStatus.PAID) {

            setDisabledInAnalysis(true);
        }

        if (updating === true || data.status === KeikenPayInvoiceStatus.DENIED || data.status === KeikenPayInvoiceStatus.PAID) {

            setDisabledReject(true);
        }
    }, [updating, data]);

    return (
        <div className="containerKeikenPayDetails">
            <div className="header">
                <div className="containerHeaderInfos">
                    <img src={'content/images/arrowLeft.png'} alt="" className="imgHeader" onClick={() => window.history.back()} />
                    <div className={'form-title-keiken-pay'}>
                        <strong>Informações do recibo</strong>
                        <div className="containerHeaderInfos">
                            <span className="titleHeader">{'Lista de recibos > '}</span>
                            <span>{data.customer?.name}</span>
                        </div>
                    </div>
                </div>
                <button onClick={download} className="buttonDownload">Baixar recibo</button>
            </div>
            <div className="form-content-keiken-pay">
                <div className="containerHeaderTableInfo">
                    <img src={'content/images/folder.png'} alt="" className="image" />
                    <div className="content">
                        <strong>{data.auxImage?.fileName ?? 'arquivo sem nome'}</strong>
                        <span>Enviado no dia {formatDate(data.billingCoParticipation?.date)}</span>
                    </div>
                    {
                        data.status === 'WAITING' || data.status === 'IN_ANALYSIS' ? (
                            <div className="status status-waiting">
                                <span>{translate(`keikenPayList.keikenPayStatus.${data.status}`)}</span>
                            </div>
                        )
                            : data.status === 'AUTHORIZED' || data.status === 'PAID' ? (
                                <div className="status status-authorized">
                                    <span>{translate(`keikenPayList.keikenPayStatus.${data.status}`)}</span>
                                </div>
                            )
                                : data.status === 'NOT_PAID' || data.status === 'DENIED' ? (
                                    <div className="status status-not-paid">
                                        <span>{translate(`keikenPayList.keikenPayStatus.${data.status}`)}</span>
                                    </div>
                                )
                                    : <span className="status status-default">{data.status}</span>
                    }
                </div>
                <div className="containerHeaderTableInfo">
                    <div className={`tab ${viewInformation === 'solicitado' ? 'tab-selected' : 'tab-unselected'}`} onClick={() => setViewInformation('solicitado')}>Solicitado</div>
                    <div className={`tab ${viewInformation === 'subsidio' ? 'tab-selected' : 'tab-unselected'}`} onClick={() => setViewInformation('subsidio')}>Subsidio</div>
                    <div className={`tab ${viewInformation === 'cliente' ? 'tab-selected' : 'tab-unselected'}`} onClick={() => setViewInformation('cliente')}>Cliente</div>
                    <div className={`tab ${viewInformation === 'parceiro externo' ? 'tab-selected' : 'tab-unselected'}`} onClick={() => setViewInformation('parceiro externo')}>Parceiro externo</div>
                    <div className={`tab ${viewInformation === 'recibo' ? 'tab-selected' : 'tab-unselected'}`} onClick={() => setViewInformation('recibo')}>Recibo</div>
                    <div className={`tab ${viewInformation === 'historico' ? 'tab-selected' : 'tab-unselected'}`} onClick={() => setViewInformation('historico')}>Histórico</div>
                </div>
                <div className="tab-content">
                    {
                        viewInformation === 'solicitado' ? <Requested data={data} />
                            : viewInformation === 'subsidio' ? <Subsidy data={data} />
                                : viewInformation === 'cliente' ? <Client data={data} />
                                    : viewInformation === 'parceiro externo' ? <PartnerExternal data={data} />
                                        : viewInformation === 'recibo' ? <Receipt concatenedImgs={concatenedImgs} handleNewImg={handleNewImg} />
                                            : viewInformation === 'historico' ? <History keikenPayPage={keikenPayPage} />
                                                : ''
                    }
                </div>
            </div>
            <div className="button-container">
                <button
                    onClick={onClickAccept}
                    disabled={disabledAccept}
                    className={`button ${disabledAccept ? 'disabled' : 'accept'}`}
                >
                    Pagar
                </button>
                <button
                    onClick={onClickAnalysis}
                    disabled={disabledInAnalysis}
                    className={`button ${disabledInAnalysis ? 'disabled' : 'analysis'}`}
                >
                    Em análise
                </button>
                <button
                    onClick={() => onClickReject()}
                    disabled={disabledReject}
                    className={`button ${disabledReject ? 'disabled' : 'reject'}`}
                >
                    Negar
                </button>
            </div>
        </div>
    );
};

const mapStateToProps = (storeState: IRootState) => ({
    keikenPayPage: storeState.keikenPay.page,
    getKeikenPaySuccess: storeState.keikenPay.getKeikenPaySuccess
});

const mapDispatchToProps = {
    getKeikenPayInvoicesProps: getKeikenPayInvoices ,
    resetKeikenPay
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Details);
