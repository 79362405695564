import React, { useState, useEffect } from 'react';
import { IRootState } from '../../../shared/reducers';
import { connect } from 'react-redux';
import {
  getSpecialties,
  createSpecialty,
  updateSpecialty,
  deleteSpecialty,
  reset
} from '../../../entities/specialty/specialty-redux';
import {
  getProfessionalCouncilDocuments
} from '../../../entities/professional-council-document/professional-council-document-redux';
import { HttpRequestStatus } from '../../../shared/model/enum/HttpRequestStatus';
import { Table, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Badge, ButtonGroup } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TailSpin } from 'react-loader-spinner';
import { toast } from 'react-toastify';
import { Specialty, CreateSpecialtyDTO, UpdateSpecialtyDTO } from '../../../shared/model/specialty';
import './styles.scss';

interface ISpecialtyManagerProps extends StateProps, DispatchProps {}

type StatusFilter = 'ALL' | 'ACTIVE' | 'INACTIVE';

const SpecialtyManager = (props: ISpecialtyManagerProps) => {
  const {
    specialties,
    getSpecialtiesStatus,
    createStatus,
    updateStatus,
    deleteStatus,
    getAllSpecialties,
    createSpecialtyAction,
    updateSpecialtyAction,
    deleteSpecialtyAction,
    resetAction,
    professionalCouncilDocuments,
    getCouncilDocuments
  } = props;

  const [specialtiesList, setSpecialtiesList] = useState<Specialty[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState<StatusFilter>('ALL');
  const [councilDocFilter, setCouncilDocFilter] = useState<number | null>(null);

  const [createModal, setCreateModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const [newSpecialty, setNewSpecialty] = useState<CreateSpecialtyDTO>({
    specialtyName: '',
    professionalCouncilDocument: { id: 0, documentCode: '', active: true }
  });
  const [editSpecialty, setEditSpecialty] = useState<UpdateSpecialtyDTO>({
    specialtyName: '',
    active: true,
    professionalCouncilDocument: { id: 0, documentCode: '', active: true }
  });
  const [selectedSpecialty, setSelectedSpecialty] = useState<Specialty | null>(null);

  useEffect(() => {
    getAllSpecialties();
    getCouncilDocuments();
  }, []);

  useEffect(() => {
    if (getSpecialtiesStatus === HttpRequestStatus.SUCCESS && specialties) {
      setSpecialtiesList(specialties);
      resetAction();
    }
  }, [getSpecialtiesStatus]);

  useEffect(() => {
    if (createStatus === HttpRequestStatus.SUCCESS) {
      toast.success('Especialidade criada com sucesso!');
      setCreateModal(false);
      setNewSpecialty({
        specialtyName: '',
        professionalCouncilDocument: { id: 0, documentCode: '', active: true }
      });
      getAllSpecialties();
      resetAction();
    } else if (createStatus === HttpRequestStatus.ERROR) {
      toast.error('Erro ao criar especialidade.');
      resetAction();
    }
  }, [createStatus]);

  useEffect(() => {
    if (updateStatus === HttpRequestStatus.SUCCESS) {
      toast.success('Especialidade atualizada com sucesso!');
      setEditModal(false);
      getAllSpecialties();
      resetAction();
    } else if (updateStatus === HttpRequestStatus.ERROR) {
      toast.error('Erro ao atualizar especialidade.');
      resetAction();
    }
  }, [updateStatus]);

  useEffect(() => {
    if (deleteStatus === HttpRequestStatus.SUCCESS) {
      toast.success('Especialidade excluída com sucesso!');
      setDeleteModal(false);
      getAllSpecialties();
      resetAction();
    } else if (deleteStatus === HttpRequestStatus.ERROR) {
      toast.error('Erro ao excluir especialidade.');
      resetAction();
    }
  }, [deleteStatus]);

  const toggleCreateModal = () => setCreateModal(!createModal);
  const toggleEditModal = () => setEditModal(!editModal);
  const toggleDeleteModal = () => setDeleteModal(!deleteModal);

  const handleCreateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewSpecialty({ ...newSpecialty, [name]: value });
  };

  const handleEditChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, type, value, checked } = e.target;
    const updatedValue = type === 'checkbox' ? checked : value;
    setEditSpecialty({ ...editSpecialty, [name]: updatedValue });
  };

  const handleCreate = (e: React.FormEvent) => {
    e.preventDefault();
    createSpecialtyAction(newSpecialty);
  };

  const handleUpdate = (e: React.FormEvent) => {
    e.preventDefault();
    if (selectedSpecialty?.id) {
      updateSpecialtyAction(selectedSpecialty.id, editSpecialty);
    }
  };

  const handleDelete = () => {
    if (selectedSpecialty?.id) {
      deleteSpecialtyAction(selectedSpecialty.id);
    }
  };

  const openEditModal = (specialty: Specialty) => {
    setSelectedSpecialty(specialty);
    setEditSpecialty({
      specialtyName: specialty.specialtyName,
      active: specialty.active,
      professionalCouncilDocument: specialty.professionalCouncilDocument
    });
    setEditModal(true);
  };

  const openDeleteModal = (specialty: Specialty) => {
    setSelectedSpecialty(specialty);
    setDeleteModal(true);
  };

  const handleStatusFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setStatusFilter(e.target.value as StatusFilter);
  };

  const handleCouncilDocFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setCouncilDocFilter(value === '' ? null : parseInt(value, 10));
  };

  const handleCouncilDocumentChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedId = parseInt(e.target.value, 10);
    const selectedDocument = professionalCouncilDocuments.find(doc => doc.id === selectedId);
    if (selectedDocument) {
      setNewSpecialty({
        ...newSpecialty,
        professionalCouncilDocument: selectedDocument
      });
    }
  };

  const handleEditCouncilDocumentChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedId = parseInt(e.target.value, 10);
    const selectedDocument = professionalCouncilDocuments.find(doc => doc.id === selectedId);
    if (selectedDocument) {
      setEditSpecialty({
        ...editSpecialty,
        professionalCouncilDocument: selectedDocument
      });
    }
  };

  const filteredSpecialties = specialtiesList.filter(specialty => {

    const matchesSearch = specialty.specialtyName.toLowerCase().includes(searchTerm.toLowerCase());

    let matchesStatus = true;
    if (statusFilter === 'ACTIVE') {
      matchesStatus = specialty.active === true;
    } else if (statusFilter === 'INACTIVE') {
      matchesStatus = specialty.active === false;
    }

    let matchesCouncilDoc = true;
    if (councilDocFilter !== null) {
      matchesCouncilDoc = specialty.professionalCouncilDocument?.id === councilDocFilter;
    }

    return matchesSearch && matchesStatus && matchesCouncilDoc;
  });

  const getStatusCount = (status: StatusFilter) => {
    if (status === 'ALL') {
      return specialtiesList.length;
    } else if (status === 'ACTIVE') {
      return specialtiesList.filter(specialty => specialty.active === true).length;
    } else {
      return specialtiesList.filter(specialty => specialty.active === false).length;
    }
  };

  return (
    <div className="specialty-container">
      <div className="header-section">
        <h2>Gerenciamento de Especialidades</h2>
        <div className="actions-container">
          <div className="search-container">
            <Input
              type="text"
              placeholder="Buscar por nome da especialidade..."
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
              className="search-input"
            />
            <FontAwesomeIcon icon="search" className="search-icon" />
          </div>

          <div className="status-filter">
            <div className="select-wrapper">
              <FontAwesomeIcon icon="filter" className="filter-icon" />
              <select
                className="status-select"
                value={statusFilter}
                onChange={handleStatusFilterChange}
              >
                <option value="ALL">Todos ({getStatusCount('ALL')})</option>
                <option value="ACTIVE">Ativos ({getStatusCount('ACTIVE')})</option>
                <option value="INACTIVE">Inativos ({getStatusCount('INACTIVE')})</option>
              </select>
              <FontAwesomeIcon icon="chevron-down" className="chevron-icon" />
            </div>
          </div>

          <div className="council-doc-filter">
            <div className="select-wrapper">
              <FontAwesomeIcon icon="file-medical" className="filter-icon" />
              <select
                className="council-doc-select"
                value={councilDocFilter || ''}
                onChange={handleCouncilDocFilterChange}
              >
                <option value="">Todos Documentos</option>
                {professionalCouncilDocuments && professionalCouncilDocuments.map((doc, index) => (
                  <option key={index} value={doc.id}>
                    {doc.documentCode}
                  </option>
                ))}
              </select>
              <FontAwesomeIcon icon="chevron-down" className="chevron-icon" />
            </div>
          </div>

          <Button color="primary" onClick={toggleCreateModal} className="create-button">
            <FontAwesomeIcon icon="plus" /> Nova Especialidade
          </Button>
        </div>
      </div>

      {getSpecialtiesStatus === HttpRequestStatus.ONGOING && (
        <div className="loading-container">
          <TailSpin height="50" width="50" color="green" />
        </div>
      )}

      {getSpecialtiesStatus === HttpRequestStatus.SUCCESS && filteredSpecialties.length === 0 ? (
        <div className="no-records">
          <FontAwesomeIcon icon="folder-open" size="3x" />
          <h4>Nenhuma especialidade encontrada</h4>
          <p>Crie uma nova especialidade ou ajuste seus critérios de busca.</p>
        </div>
      ) : (
        <Table className="specialties-table" responsive hover>
          <thead>
            <tr>
              <th>ID</th>
              <th>Nome da Especialidade</th>
              <th>Documento de Conselho</th>
              <th>Status</th>
              <th className="text-center">Ações</th>
            </tr>
          </thead>
          <tbody>
            {filteredSpecialties.map((specialty, index) => (
              <tr key={index} className={index % 2 === 0 ? 'even-row' : 'odd-row'}>
                <td>{specialty.id}</td>
                <td>{specialty.specialtyName}</td>
                <td>{specialty.professionalCouncilDocument?.documentCode}</td>
                <td>
                  {specialty.active ? (
                    <Badge color="success" pill>Ativo</Badge>
                  ) : (
                    <Badge color="secondary" pill>Inativo</Badge>
                  )}
                </td>
                <td className="text-center">
                  <Button color="info" size="sm" className="action-button" onClick={() => openEditModal(specialty)}>
                    <FontAwesomeIcon icon="edit" />
                  </Button>
                  <Button color="danger" size="sm" className="action-button" onClick={() => openDeleteModal(specialty)}>
                    <FontAwesomeIcon icon="trash" />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      <Modal isOpen={createModal} toggle={toggleCreateModal}>
        <ModalHeader toggle={toggleCreateModal}>Nova Especialidade</ModalHeader>
        <Form onSubmit={handleCreate}>
          <ModalBody>
            <FormGroup>
              <Label for="specialtyName">Nome da Especialidade</Label>
              <Input
                type="text"
                name="specialtyName"
                id="specialtyName"
                value={newSpecialty.specialtyName}
                onChange={handleCreateChange}
                placeholder="Digite o nome da especialidade"
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="professionalCouncilDocument">Documento de Conselho Profissional</Label>
              <Input
                type="select"
                name="professionalCouncilDocument"
                id="professionalCouncilDocument"
                onChange={handleCouncilDocumentChange}
                required
              >
                <option value="">Selecione um documento</option>
                {professionalCouncilDocuments && professionalCouncilDocuments.map((doc, index) => (
                  <option key={index} value={doc.id}>
                    {doc.documentCode}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleCreateModal}>Cancelar</Button>
            <Button
              color="primary"
              type="submit"
              disabled={createStatus === HttpRequestStatus.ONGOING}
            >
              {createStatus === HttpRequestStatus.ONGOING ? (
                <span><TailSpin height="15" width="15" color="white" /> Salvando...</span>
              ) : 'Salvar'}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={editModal} toggle={toggleEditModal}>
        <ModalHeader toggle={toggleEditModal}>Editar Especialidade</ModalHeader>
        <Form onSubmit={handleUpdate}>
          <ModalBody>
            <FormGroup>
              <Label for="specialtyName">Nome da Especialidade</Label>
              <Input
                type="text"
                name="specialtyName"
                id="editSpecialtyName"
                value={editSpecialty.specialtyName}
                onChange={handleEditChange}
                placeholder="Digite o nome da especialidade"
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="professionalCouncilDocument">Documento de Conselho Profissional</Label>
              <Input
                type="select"
                name="professionalCouncilDocument"
                id="editProfessionalCouncilDocument"
                value={editSpecialty.professionalCouncilDocument?.id}
                onChange={handleEditCouncilDocumentChange}
                required
              >
                <option value="">Selecione um documento</option>
                {professionalCouncilDocuments && professionalCouncilDocuments.map((doc, index) => (
                  <option key={index} value={doc.id}>
                    {doc.documentCode}
                  </option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  name="active"
                  checked={editSpecialty.active}
                  onChange={handleEditChange}
                />{' '}
                Ativo
              </Label>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleEditModal}>Cancelar</Button>
            <Button
              color="primary"
              type="submit"
              disabled={updateStatus === HttpRequestStatus.ONGOING}
            >
              {updateStatus === HttpRequestStatus.ONGOING ? (
                <span><TailSpin height="15" width="15" color="white" /> Atualizando...</span>
              ) : 'Atualizar'}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={deleteModal} toggle={toggleDeleteModal}>
        <ModalHeader toggle={toggleDeleteModal}>Confirmar Exclusão</ModalHeader>
        <ModalBody>
          <p>Você tem certeza que deseja excluir a especialidade <strong>{selectedSpecialty?.specialtyName}</strong>?</p>
          <p className="text-danger">Esta ação não pode ser desfeita.</p>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleDeleteModal}>Cancelar</Button>
          <Button
            color="danger"
            onClick={handleDelete}
            disabled={deleteStatus === HttpRequestStatus.ONGOING}
          >
            {deleteStatus === HttpRequestStatus.ONGOING ? (
              <span><TailSpin height="15" width="15" color="white" /> Excluindo...</span>
            ) : 'Excluir'}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  specialties: storeState.specialty.specialties,
  getSpecialtiesStatus: storeState.specialty.getSpecialtiesStatus,
  createStatus: storeState.specialty.createStatus,
  updateStatus: storeState.specialty.updateStatus,
  deleteStatus: storeState.specialty.deleteStatus,
  professionalCouncilDocuments: storeState.professionalCouncilDocument.professionalCouncilDocuments
});

const mapDispatchToProps = {
  getAllSpecialties: getSpecialties,
  createSpecialtyAction: createSpecialty,
  updateSpecialtyAction: updateSpecialty,
  deleteSpecialtyAction: deleteSpecialty,
  resetAction: reset,
  getCouncilDocuments: getProfessionalCouncilDocuments
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SpecialtyManager);
