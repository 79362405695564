import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import React from 'react';
import IActivityPlanList from './activity-plan/activity-plan-list';
import IActivityPlanManage from './activity-plan/activity-plan-manage';
import ApplicationDashboard from './application-dashboard/application-dashboard';
import ApproveActivities from './approve-activities/approve-activities';
import ApproveCustomerActivities from './approve-customer-activities/approve-customer-activities';
import ApprovedCustomerActivities from './approved-customer-activities/approved-customer-activities';
import CategoryList from './categories-list/categories-list';
import CategoryManage from './category-manage/category-manage';
import CompaniesList from './companies-list/companies-list';
import CompanyManage from './companies-list/company-manage/company-manage';
import Faqlist from './faq-list/faq-list';
import FaqManage from './faq-manager/faq-manage';
import IKeikenPayList from './keiken-pay/keiken-pay-list';
import IKeikenPayManage from './keiken-pay/keiken-pay-manage';
import PartnerPlaceList from './partner-place-list/partner-place-list';
import RenewalActivityScheduleScreen from './renewal-activity-schedule-list/renewal-activity-schedule-list';
import RenewalActivityScheduleManage from './renewal-activity-schedule-manage/renewal-activity-schedule-manage';
import ScheduleManage from './schedule-manage/schedule-manage';
import SubCategoryList from './subcategories-list/subcategories-list';
import SubCategoryManage from './subcategory-manage/subcategory-manage';
import PriceTablePageScreen from './price-table-screen-list/price-table-screen-list';
import SystemFeeManage from './system-fee/system-fee-manage';
import PriceTableManageScreen from './price-table-screen-list/price-table-manage-screen';
import KeikenPayPartnerExternalManage from './keiken-pay-partner-external/keiken-pay-partner-external-manage';
import KeikenPayPartnerExternalList from './keiken-pay-partner-external/keiken-pay-partner-external';
import IBillingsList from './billings-list/billings-list';
import IBillingsManage from './billings-manage/billings-manage';
import IAssessmentList from './assessment-list/assessment-list';
import IPartnerPlaceManage from './partner-place-manage/partner-place-manage';
import ICustomerList from './customers-list/customers-list';
import ICustomersManage from './customers-manage/customers-manage';
import ICustomerWallet from './customers-manage/components/customer-wallet';
import IPartnerPlaceWallet from './customers-manage/components/partner-place-wallet';
import ICompanyWallet from './customers-manage/components/company-wallet';
import ActivitiesManage from './activities-list/activities-list';
import ActivityManage from './activity-manage';
import Conferences from './conferences';
import partnersPayment from './partners-payment';
import companyKeikenpayfeeManage from './companies-list/company-manage/company-keikenpayfee-manage';
import WithdrawsNF from './withdraw-nf';

const Routes = ({ match }) => (
  <div>
    <ErrorBoundaryRoute path={`${match.url}/application-dashboard`} component={ApplicationDashboard} />
    <ErrorBoundaryRoute exact path={`${match.url}/partner-place/:id`} component={IPartnerPlaceManage} />
    <ErrorBoundaryRoute exact path={`${match.url}/partner-place/:id/wallet`} component={IPartnerPlaceWallet} />
    <ErrorBoundaryRoute path={`${match.url}/partner-place-list`} component={PartnerPlaceList} />
    <ErrorBoundaryRoute exact path={`${match.url}/customers`} component={ICustomerList} />
    <ErrorBoundaryRoute exact path={`${match.url}/customers/:id`} component={ICustomersManage} />
    <ErrorBoundaryRoute exact path={`${match.url}/customers/:id/wallet`} component={ICustomerWallet} />
    <ErrorBoundaryRoute exact path={`${match.url}/billings`} component={IBillingsList} />
    <ErrorBoundaryRoute exact path={`${match.url}/billings/:id`} component={IBillingsManage} />
    <ErrorBoundaryRoute path={`${match.url}/system-fee-manage`} component={SystemFeeManage} />
    <ErrorBoundaryRoute exact path={`${match.url}/activities-manage`} component={ActivitiesManage} />
    <ErrorBoundaryRoute exact path={`${match.url}/activities-manage/:id`} component={ActivityManage} />
    <ErrorBoundaryRoute path={`${match.url}/approve-activities`} component={ApproveActivities} />
    <ErrorBoundaryRoute path={`${match.url}/approve-customer-activities`} component={ApproveCustomerActivities} />
    <ErrorBoundaryRoute path={`${match.url}/approved-customer-activities`} component={ApprovedCustomerActivities} />
    <ErrorBoundaryRoute path={`${match.url}/categories-list`} component={CategoryList} />
    <ErrorBoundaryRoute path={`${match.url}/companies-list`} component={CompaniesList} />
    <ErrorBoundaryRoute exact path={`${match.url}/companies-manage`} component={CompanyManage} />
    <ErrorBoundaryRoute exact path={`${match.url}/companies-manage/:id`} component={CompanyManage} />
    <ErrorBoundaryRoute exact path={`${match.url}/companies-manage/:id/keiken-pay-fee`} component={companyKeikenpayfeeManage} />
    <ErrorBoundaryRoute exact path={`${match.url}/companies-manage/:id/wallet`} component={ICompanyWallet} />
    <ErrorBoundaryRoute path={`${match.url}/subcategories-list`} component={SubCategoryList} />
    <ErrorBoundaryRoute path={`${match.url}/category-manage`} component={CategoryManage} />
    <ErrorBoundaryRoute path={`${match.url}/subcategory-manage`} component={SubCategoryManage} />
    <ErrorBoundaryRoute path={`${match.url}/faq-list`} component={Faqlist} />
    <ErrorBoundaryRoute path={`${match.url}/faq-manage`} component={FaqManage} />
    <ErrorBoundaryRoute exact path={`${match.url}/schedule-manage`} component={ScheduleManage} />
    <ErrorBoundaryRoute exact path={`${match.url}/schedule-manage/edit`} component={ScheduleManage} />
    <ErrorBoundaryRoute exact path={`${match.url}/keiken-pay-invoice`} component={IKeikenPayList} />
    <ErrorBoundaryRoute exact path={`${match.url}/keiken-pay-invoice/:id`} component={IKeikenPayManage} />
    <ErrorBoundaryRoute exact path={`${match.url}/activity-plan`} component={IActivityPlanList} />
    <ErrorBoundaryRoute exact path={`${match.url}/activity-plan/:id`} component={IActivityPlanManage} />
    <ErrorBoundaryRoute exact path={`${match.url}/renewal-activity-schedule`} component={RenewalActivityScheduleScreen} />
    <ErrorBoundaryRoute exact path={`${match.url}/renewal-activity-schedule/:id`} component={RenewalActivityScheduleManage} />
    <ErrorBoundaryRoute exact path={`${match.url}/price-table`} component={PriceTablePageScreen} />
    <ErrorBoundaryRoute exact path={`${match.url}/price-table-manage`} component={PriceTableManageScreen} />
    <ErrorBoundaryRoute exact path={`${match.url}/price-table-manage/:id`} component={PriceTableManageScreen} />
    <ErrorBoundaryRoute exact path={`${match.url}/keiken-pay-partner-external`} component={KeikenPayPartnerExternalList} />
    <ErrorBoundaryRoute exact path={`${match.url}/keiken-pay-partner-external/:id`} component={KeikenPayPartnerExternalManage} />
    <ErrorBoundaryRoute exact path={`${match.url}/assessments`} component={IAssessmentList} />
    <ErrorBoundaryRoute exact path={`${match.url}/conferences`} component={Conferences} />
    <ErrorBoundaryRoute exact path={`${match.url}/partnersPayment`} component={partnersPayment} />
    <ErrorBoundaryRoute exact path={`${match.url}/withdrawNF`} component={WithdrawsNF} />
  </div>
);

export default Routes;
