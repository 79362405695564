import * as React from 'react';
import { connect } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { RouteComponentProps } from 'react-router-dom';
import { updateKeikenPayInvoiceStatus, getKeikenPayInvoice, reset, updateKeikenPayInvoice } from 'app/entities/keiken-pay/keiken-pay-reducer';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/fontawesome-free-regular';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { showModal, hideModal } from 'app/components/modal/modal-action';
import CoolButton from 'app/components/button/Button';
import { BillingCoParticipationType, KeikenPayInvoice, KeikenPayInvoiceStatus } from 'app/shared/model/KeikenPay/KeikenPayInvoice';
import { Row, Col } from 'reactstrap';
import { RefundAccountType } from 'app/shared/model/KeikenPay/RefundAccountType';
import { translate } from 'react-jhipster';
import StringUtils from 'app/util/StringUtils';
import { IKeikenPayPartnerExternal } from 'app/shared/model/KeikenPay/KeikenPayPartnerExternal';
import './keiken-pay-list.scss';
import { toast } from 'react-toastify';
import moment from 'moment';
import { IImage } from 'app/shared/model/image';
import KeykenPayImg from './keiken-pay-img';
import { MODAL_APPROVE_KEIKEN_PAY, MODAL_DENY_KEIKEN_PAY } from '../../../components/modal/modal-types';
import Details from './KeikenPayDetails/Details';
export interface IKeikenPayManageProps
  extends StateProps,
  DispatchProp,
  RouteComponentProps<any, any, { invoice: KeikenPayInvoice } | any> { }

interface EditingKeikenPayInvoice {
  description?: string;
  date?: string;
  image?: IImage;
  auxUserImages?: IImage[];
  auxImage?: IImage;
}

export interface IKeikenPayManageState {
  invoice?: KeikenPayInvoice;
  editingInvoice?: EditingKeikenPayInvoice;
  updating: boolean;
  inputRefImage: React.RefObject<HTMLInputElement>;
  inputRefAuxImage: React.RefObject<HTMLInputElement>;
}

export class IKeikenPayManage extends React.Component<IKeikenPayManageProps, IKeikenPayManageState> {
  constructor(props) {
    super(props);
    this.state = {
      updating: false,
      invoice: {},
      editingInvoice: {},
      inputRefImage: React.createRef(),
      inputRefAuxImage: React.createRef()
    };
  }

  componentDidMount() {
    if (this.props.location.state?.invoice != null) {
      this.setState({
        invoice: this.props.location.state.invoice,
        editingInvoice: {
          image: this.props.location.state.invoice.image,
          auxUserImages: this.props.location.state.invoice.auxUserImages,
          auxImage: this.props.location.state.invoice.auxImage,
          description: this.props.location.state.invoice.description,
          date: this.props.location.state.invoice.billingCoParticipation.date
        }
      });
    } else {
      if (this.props.match.params?.id != null) {
        this.fetchByMatchParamId();
      }
    }
  }

  private fetchByMatchParamId() {
    this.props.getKeikenPayInvoice(this.props.match.params?.id);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.getInvoiceStatus === HttpRequestStatus.SUCCESS) {
      this.setState({
        invoice: newProps.invoice,
        editingInvoice: {
          image: newProps.invoice.image,
          auxUserImages: newProps.invoice.auxUserImages,
          auxImage: newProps.invoice.auxImage,
          description: newProps.invoice.description,
          date: newProps.invoice.billingCoParticipation.date
        }
      });
      this.props.reset();
    }
    if (newProps.updateInvoiceStatus === HttpRequestStatus.SUCCESS) {
      toast.success(translate('keikenPayList.toast.update-success'));
      this.props.reset();
      this.setState({
        invoice: newProps.invoice,
        updating: false
      }, () => this.fetchByMatchParamId());
    } else if (newProps.updateInvoiceStatus === HttpRequestStatus.ERROR) {
      this.props.reset();
      this.setState({ updating: false }, () => this.fetchByMatchParamId());
      toast.error(translate('keikenPayList.toast.update-error'));
    }
  }

  private handleUpdateStatus = (status: KeikenPayInvoiceStatus) => {
    this.setState({
      updating: true
    }, () => {
      this.props.updateKeikenPayInvoiceStatus(this.state.invoice.id, status, undefined, undefined, undefined);
    });
  }

  private handleUpdateInvoice = () => {
    const { auxImage, date, description, image, auxUserImages } = this.state.editingInvoice;
    this.setState({
      updating: true
    }, () => {
      this.props.updateKeikenPayInvoice({
        id: this.state.invoice.id,
        description,
        image,
        auxImage,
        auxUserImages,
        billingCoParticipation: {
          id: this.state.invoice.billingCoParticipation.id,
          date: moment(date).toISOString()
        }
      });
    });
  }

  private handleEdit = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    this.setState({ editingInvoice: {...this.state.editingInvoice, [name]: value} });
  }

  private handleNewImg = (newImage: IImage, type: 'MAIN' | 'AUX' | 'USERAUX', replaceIdx?: number) => {
    if (type === 'MAIN') {
      this.setState(prevState => ({ editingInvoice: { ...prevState.editingInvoice, image: newImage } }));
    } else if (type === 'AUX') {
      this.setState(prevState => ({ editingInvoice: { ...prevState.editingInvoice, auxImage: newImage } }));
    } else {

      const auxUserImages = this.state.editingInvoice.auxUserImages ?? [];
      if (replaceIdx != null) {
        auxUserImages[replaceIdx] = newImage;
      } else {
        auxUserImages.push(newImage);
      }
      this.setState(prevState => ({ editingInvoice: { ...prevState.editingInvoice, auxUserImages } }));
    }
  }

  private handleOpenDenyKeikenPayModal = (invoice: KeikenPayInvoice | undefined) => {
    this.props.showModal(MODAL_DENY_KEIKEN_PAY, {
      invoice,
      onClose: () => this.props.hideModal()
    });
  };

  private handleOpenAcceptKeikenPayModal = (invoice: KeikenPayInvoice | undefined) => {
    this.props.showModal(MODAL_APPROVE_KEIKEN_PAY, {
      invoice,
      onClose: () => this.props.hideModal()
    });
  }

  render() {
    const invoice = this.state.invoice;
    const editingInvoice = this.state.editingInvoice;
    const auxUserImgs = editingInvoice?.auxUserImages ?? [];
    const mainsImg = editingInvoice.image ? [editingInvoice.image] : [];
    const concatenedImgs = [...mainsImg, ...auxUserImgs];
    return (
        <Details
            data={invoice}
            concatenedImgs={concatenedImgs}
            handleNewImg={this.handleNewImg}
            onClickAccept={() => this.handleOpenAcceptKeikenPayModal(invoice)}
            onClickAnalysis={() => this.handleUpdateStatus(KeikenPayInvoiceStatus.IN_ANALYSIS)}
            onClickReject={() => this.handleOpenDenyKeikenPayModal(invoice)}
            updating={this.state.updating}
          />
      );
      {/* <div className={'form-container'}>
        <div className={'title-screen-container'}>
          <div onClick={() => this.props.history.goBack()}>
            <FontAwesomeIcon className={'back-arrow-icon'} icon={'arrow-left'} />
          </div>
          <label className={'faq-manage-title'}></label>
        </div>

        <Row>
          <Col md="4">
            <InvoiceSummary invoice={invoice} handleEdit={this.handleEdit} description={editingInvoice?.description} date={editingInvoice?.date}/>
            <CustomerInvoiceData invoice={invoice} />
            <PartnerExternalInvoiceData partnerExternal={invoice.partnerExternal} />
            <div className="save-btn-keiken-pay">
              <CoolButton
                width={'380px'}
                onClick={() => this.handleUpdateInvoice()}
                title={translate('keikenPayList.button.save')}
                buttonDisabled={this.state.updating === true }
              />
            </div>
          </Col>

          <Col md="4">
            <Row>
              <p style={{ fontWeight: 'bold' }}>
                {'status: '}{invoice?.status && translate(`keikenPayList.keikenPayStatus.${invoice?.status}`)}
              </p>
            </Row>
            {concatenedImgs.map((img, idx) => (
              <KeykenPayImg
                key={`${idx}`}
                img={img}
                handleNewImg={this.handleNewImg}
                type={idx === 0 ? 'MAIN' : 'USERAUX'}
                idx={idx === 0 ? undefined : idx - 1}
              />
            ))}
            <Row style={{marginTop: '20px'}}>
              <div className={'marginT15'}>
                <CoolButton
                  width={'380px'}
                  onClick={() => this.handleOpenAcceptKeikenPayModal(invoice)}
                  title={translate('keikenPayList.button.paid')}
                  buttonDisabled={this.state.updating == true || invoice.status == KeikenPayInvoiceStatus.DENIED || invoice.status == KeikenPayInvoiceStatus.PAID}
                />
              </div>
            </Row>
            <Row>
              <div className={'marginT15'}>
                <CoolButton
                  variant={'warning'}
                  onClick={() => this.handleUpdateStatus(KeikenPayInvoiceStatus.IN_ANALYSIS)}
                  title={translate('keikenPayList.button.in-analysis')}
                  buttonDisabled={this.state.updating == true || invoice.status == KeikenPayInvoiceStatus.DENIED || invoice.status == KeikenPayInvoiceStatus.PAID}
                />
              </div>
              <div style={{ marginLeft: 20 }} className={'marginT15'}>
                <CoolButton
                  variant={'problem'}
                  onClick={() => this.handleOpenDenyKeikenPayModal(invoice)}
                  title={translate('keikenPayList.button.deny')}
                  buttonDisabled={this.state.updating == true || invoice.status == KeikenPayInvoiceStatus.DENIED || invoice.status == KeikenPayInvoiceStatus.PAID}
                />
              </div>
            </Row>
          </Col>

          <Col md="4">
            <Row><p>Foto auxiliar</p></Row>
            <KeykenPayImg
              img={editingInvoice.auxImage}
              handleNewImg={this.handleNewImg}
              type={'AUX'}
            />
          </Col>
        </Row>
      </div> */}
  }
}

const mapStateToProps = (root: IRootState) => ({
  getInvoiceStatus: root.keikenPay.getInvoiceStatus,
  updateInvoiceStatus: root.keikenPay.updateInvoiceStatus,
  invoice: root.keikenPay.invoice
});

const mapDispatchToProps = {
  showModal,
  hideModal,
  reset,
  getKeikenPayInvoice,
  updateKeikenPayInvoiceStatus,
  updateKeikenPayInvoice
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProp = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IKeikenPayManage);
