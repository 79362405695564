import { HttpRequestStatus } from '../../shared/model/enum/HttpRequestStatus';
import { FAILURE, REQUEST, SUCCESS } from '../../shared/reducers/action-type.util';
import axios from 'axios';
import { Specialty, CreateSpecialtyDTO, UpdateSpecialtyDTO, GetSpecialtyDTO } from '../../shared/model/specialty';

export const ACTION_TYPES = {
  GET_SPECIALTIES: 'specialty/GET_SPECIALTIES',
  GET_SPECIALTY: 'specialty/GET_SPECIALTY',
  CREATE_SPECIALTY: 'specialty/CREATE_SPECIALTY',
  UPDATE_SPECIALTY: 'specialty/UPDATE_SPECIALTY',
  DELETE_SPECIALTY: 'specialty/DELETE_SPECIALTY',
  GET_SPECIALTIES_BY_DOCUMENT_ID: 'specialty/GET_SPECIALTIES_BY_DOCUMENT_ID',
  CLEAR_SPECIALTIES_BY_DOCUMENT: 'specialty/CLEAR_SPECIALTIES_BY_DOCUMENT',
  RESET: 'specialty/RESET'
};

export const initialState = {
  specialties: [] as Specialty[],
  specialty: null as Specialty | null,
  specialtiesByDocument: [] as Specialty[],
  getSpecialtiesStatus: HttpRequestStatus.NOOP,
  getSpecialtyStatus: HttpRequestStatus.NOOP,
  getSpecialtiesByDocumentStatus: HttpRequestStatus.NOOP,
  createStatus: HttpRequestStatus.NOOP,
  updateStatus: HttpRequestStatus.NOOP,
  deleteStatus: HttpRequestStatus.NOOP
};

export type ISpecialtyState = Readonly<typeof initialState>;

export default (state: ISpecialtyState = initialState, action): ISpecialtyState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.GET_SPECIALTIES):
      return {
        ...state,
        getSpecialtiesStatus: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.GET_SPECIALTIES):
      return {
        ...state,
        getSpecialtiesStatus: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.GET_SPECIALTIES):
      return {
        ...state,
        getSpecialtiesStatus: HttpRequestStatus.SUCCESS,
        specialties: action.payload.data
      };
    case REQUEST(ACTION_TYPES.GET_SPECIALTY):
      return {
        ...state,
        getSpecialtyStatus: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.GET_SPECIALTY):
      return {
        ...state,
        getSpecialtyStatus: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.GET_SPECIALTY):
      return {
        ...state,
        getSpecialtyStatus: HttpRequestStatus.SUCCESS,
        specialty: action.payload.data
      };
    case REQUEST(ACTION_TYPES.GET_SPECIALTIES_BY_DOCUMENT_ID):
      return {
        ...state,
        getSpecialtiesByDocumentStatus: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.GET_SPECIALTIES_BY_DOCUMENT_ID):
      return {
        ...state,
        getSpecialtiesByDocumentStatus: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.GET_SPECIALTIES_BY_DOCUMENT_ID):
      return {
        ...state,
        getSpecialtiesByDocumentStatus: HttpRequestStatus.SUCCESS,
        specialtiesByDocument: action.payload.data
      };
    case ACTION_TYPES.CLEAR_SPECIALTIES_BY_DOCUMENT:
      return {
        ...state,
        specialtiesByDocument: [],
        getSpecialtiesByDocumentStatus: HttpRequestStatus.NOOP
      };
    case REQUEST(ACTION_TYPES.CREATE_SPECIALTY):
      return {
        ...state,
        createStatus: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.CREATE_SPECIALTY):
      return {
        ...state,
        createStatus: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.CREATE_SPECIALTY):
      return {
        ...state,
        createStatus: HttpRequestStatus.SUCCESS
      };
    case REQUEST(ACTION_TYPES.UPDATE_SPECIALTY):
      return {
        ...state,
        updateStatus: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.UPDATE_SPECIALTY):
      return {
        ...state,
        updateStatus: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.UPDATE_SPECIALTY):
      return {
        ...state,
        updateStatus: HttpRequestStatus.SUCCESS
      };
    case REQUEST(ACTION_TYPES.DELETE_SPECIALTY):
      return {
        ...state,
        deleteStatus: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.DELETE_SPECIALTY):
      return {
        ...state,
        deleteStatus: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.DELETE_SPECIALTY):
      return {
        ...state,
        deleteStatus: HttpRequestStatus.SUCCESS
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = '/api/specialty';

export const getSpecialties = () => ({
  type: ACTION_TYPES.GET_SPECIALTIES,
  payload: axios.get(apiUrl)
});

export const getSpecialty = (id: number) => ({
  type: ACTION_TYPES.GET_SPECIALTY,
  payload: axios.get(`${apiUrl}/${id}`)
});

export const getSpecialtiesByDocumentId = (documentId: number) => ({
  type: ACTION_TYPES.GET_SPECIALTIES_BY_DOCUMENT_ID,
  payload: axios.get(`${apiUrl}/document/${documentId}`)
});

export const clearSpecialtiesByDocument = () => ({
  type: ACTION_TYPES.CLEAR_SPECIALTIES_BY_DOCUMENT
});

export const createSpecialty = (specialty: CreateSpecialtyDTO) => ({
  type: ACTION_TYPES.CREATE_SPECIALTY,
  payload: axios.post(apiUrl, specialty)
});

export const updateSpecialty = (id: number, specialty: UpdateSpecialtyDTO) => ({
  type: ACTION_TYPES.UPDATE_SPECIALTY,
  payload: axios.put(`${apiUrl}/${id}`, specialty)
});

export const deleteSpecialty = (id: number) => ({
  type: ACTION_TYPES.DELETE_SPECIALTY,
  payload: axios.delete(`${apiUrl}/${id}`)
});

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
