import { HttpRequestStatus } from '../../shared/model/enum/HttpRequestStatus';
import { FAILURE, REQUEST, SUCCESS } from '../../shared/reducers/action-type.util';
import axios from 'axios';

export interface ProfessionalCouncilDocumentDTO {
  id?: number;
  documentCode: string;
  active?: boolean;
}

export interface CreateProfessionalCouncilDocumentDTO {
  documentCode: string;
}

export const ACTION_TYPES = {
  GET_PROFESSIONAL_COUNCIL_DOCUMENTS: 'professionalCouncilDocument/GET_PROFESSIONAL_COUNCIL_DOCUMENTS',
  GET_PROFESSIONAL_COUNCIL_DOCUMENT: 'professionalCouncilDocument/GET_PROFESSIONAL_COUNCIL_DOCUMENT',
  CREATE_PROFESSIONAL_COUNCIL_DOCUMENT: 'professionalCouncilDocument/CREATE_PROFESSIONAL_COUNCIL_DOCUMENT',
  UPDATE_PROFESSIONAL_COUNCIL_DOCUMENT: 'professionalCouncilDocument/UPDATE_PROFESSIONAL_COUNCIL_DOCUMENT',
  DELETE_PROFESSIONAL_COUNCIL_DOCUMENT: 'professionalCouncilDocument/DELETE_PROFESSIONAL_COUNCIL_DOCUMENT',
  RESET: 'professionalCouncilDocument/RESET'
};

export const initialState = {
  professionalCouncilDocuments: [] as ProfessionalCouncilDocumentDTO[],
  professionalCouncilDocument: null as ProfessionalCouncilDocumentDTO | null,
  getDocumentsStatus: HttpRequestStatus.NOOP,
  getDocumentStatus: HttpRequestStatus.NOOP,
  createStatus: HttpRequestStatus.NOOP,
  updateStatus: HttpRequestStatus.NOOP,
  deleteStatus: HttpRequestStatus.NOOP
};

export type IProfessionalCouncilDocumentState = Readonly<typeof initialState>;

export default (state: IProfessionalCouncilDocumentState = initialState, action): IProfessionalCouncilDocumentState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.GET_PROFESSIONAL_COUNCIL_DOCUMENTS):
      return {
        ...state,
        getDocumentsStatus: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.GET_PROFESSIONAL_COUNCIL_DOCUMENTS):
      return {
        ...state,
        getDocumentsStatus: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.GET_PROFESSIONAL_COUNCIL_DOCUMENTS):
      return {
        ...state,
        getDocumentsStatus: HttpRequestStatus.SUCCESS,
        professionalCouncilDocuments: action.payload.data
      };
    case REQUEST(ACTION_TYPES.GET_PROFESSIONAL_COUNCIL_DOCUMENT):
      return {
        ...state,
        getDocumentStatus: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.GET_PROFESSIONAL_COUNCIL_DOCUMENT):
      return {
        ...state,
        getDocumentStatus: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.GET_PROFESSIONAL_COUNCIL_DOCUMENT):
      return {
        ...state,
        getDocumentStatus: HttpRequestStatus.SUCCESS,
        professionalCouncilDocument: action.payload.data
      };
    case REQUEST(ACTION_TYPES.CREATE_PROFESSIONAL_COUNCIL_DOCUMENT):
      return {
        ...state,
        createStatus: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.CREATE_PROFESSIONAL_COUNCIL_DOCUMENT):
      return {
        ...state,
        createStatus: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.CREATE_PROFESSIONAL_COUNCIL_DOCUMENT):
      return {
        ...state,
        createStatus: HttpRequestStatus.SUCCESS
      };
    case REQUEST(ACTION_TYPES.UPDATE_PROFESSIONAL_COUNCIL_DOCUMENT):
      return {
        ...state,
        updateStatus: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.UPDATE_PROFESSIONAL_COUNCIL_DOCUMENT):
      return {
        ...state,
        updateStatus: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.UPDATE_PROFESSIONAL_COUNCIL_DOCUMENT):
      return {
        ...state,
        updateStatus: HttpRequestStatus.SUCCESS
      };
    case REQUEST(ACTION_TYPES.DELETE_PROFESSIONAL_COUNCIL_DOCUMENT):
      return {
        ...state,
        deleteStatus: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.DELETE_PROFESSIONAL_COUNCIL_DOCUMENT):
      return {
        ...state,
        deleteStatus: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.DELETE_PROFESSIONAL_COUNCIL_DOCUMENT):
      return {
        ...state,
        deleteStatus: HttpRequestStatus.SUCCESS
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = '/api/professional-council-documents';

export const getProfessionalCouncilDocuments = () => ({
  type: ACTION_TYPES.GET_PROFESSIONAL_COUNCIL_DOCUMENTS,
  payload: axios.get(apiUrl)
});

export const getProfessionalCouncilDocument = (id: number) => ({
  type: ACTION_TYPES.GET_PROFESSIONAL_COUNCIL_DOCUMENT,
  payload: axios.get(`${apiUrl}/${id}`)
});

export const createProfessionalCouncilDocument = (document: CreateProfessionalCouncilDocumentDTO) => ({
  type: ACTION_TYPES.CREATE_PROFESSIONAL_COUNCIL_DOCUMENT,
  payload: axios.post(apiUrl, document)
});

export const updateProfessionalCouncilDocument = (id: number, document: ProfessionalCouncilDocumentDTO) => ({
  type: ACTION_TYPES.UPDATE_PROFESSIONAL_COUNCIL_DOCUMENT,
  payload: axios.put(`${apiUrl}/${id}`, document)
});

export const deleteProfessionalCouncilDocument = (id: number) => ({
  type: ACTION_TYPES.DELETE_PROFESSIONAL_COUNCIL_DOCUMENT,
  payload: axios.delete(`${apiUrl}/${id}`)
});

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
