import React, { useState } from 'react';
import KeykenPayImg from '../../keiken-pay-img';
import { IImage } from '../../../../../shared/model/image';
import './menus.scss';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

interface Props {
  concatenedImgs: IImage[];
  handleNewImg: (newImage: IImage, type: 'MAIN' | 'AUX' | 'USERAUX', replaceIdx?: number | undefined) => void;
}

export const Receipt = ({ concatenedImgs, handleNewImg }: Props) => {
  const [showModal, setShowModal] = useState(false);
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const [originalUrl, setOriginalUrl] = useState<string | null>(null);
  const [fileName, setFileName] = useState('documento.pdf');
  const [isLoading, setIsLoading] = useState(false);
  const [pdfsLoaded, setPdfsLoaded] = useState<Record<string, boolean>>({});

  const isPdf = (img: IImage): boolean => {
    return img?.contentType?.includes('pdf') || img?.fileName?.toLowerCase().endsWith('.pdf') || false;
  };

  const getPdfViewUrl = (img: IImage): string => {
    if (img.imageUrl) {
      return `https://docs.google.com/viewer?url=${encodeURIComponent(img.imageUrl)}&embedded=true`;
    } else if (img.file) {
      return `data:${img.contentType || 'application/pdf'};base64,${img.file}`;
    }
    return '';
  };

  const getOriginalUrl = (img: IImage): string => {
    if (img.imageUrl) {
      return img.imageUrl;
    } else if (img.file) {
      return `data:${img.contentType || 'application/pdf'};base64,${img.file}`;
    }
    return '';
  };

  const handleIframeLoad = (iframeId: string) => {
    setPdfsLoaded(prev => ({
      ...prev,
      [iframeId]: true
    }));

    if (iframeId === 'modal-pdf') {
      setIsLoading(false);
    }
  };

  const openFullscreenPdfViewer = (img: IImage) => {
    if (isPdf(img)) {
      const viewUrl = getPdfViewUrl(img);
      const original = getOriginalUrl(img);

      if (viewUrl) {
        setIsLoading(true);
        setPdfUrl(viewUrl);
        setOriginalUrl(original);
        setFileName(img.fileName || 'documento.pdf');
        setShowModal(true);

        setTimeout(() => {
          if (isLoading) {
            setPdfUrl('');
            setTimeout(() => {
              setPdfUrl(viewUrl);
            }, 50);
          }
        }, 3000);
      }
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setPdfUrl(null);
    setOriginalUrl(null);
    setIsLoading(false);
  };

  const downloadPdf = (img: IImage) => {
    const url = getOriginalUrl(img);
    if (url) {
      const link = document.createElement('a');
      link.href = url;
      link.download = img.fileName || 'documento.pdf';
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const downloadModalPdf = () => {
    if (originalUrl) {
      const link = document.createElement('a');
      link.href = originalUrl;
      link.download = fileName;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const renderPdfViewer = (url: string, iframeId: string, height = '300px') => {
    const isThisPdfLoaded = pdfsLoaded[iframeId];

    return (
      <div style={{ position: 'relative', height, width: '100%' }}>
        {!isThisPdfLoaded && (
          <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#f8f9fa',
            zIndex: 1
          }}>
            <div>
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Carregando...</span>
              </div>
              <div style={{ marginTop: '10px', textAlign: 'center' }}>
                Carregando PDF...
              </div>
            </div>
          </div>
        )}
        <iframe
          id={iframeId}
          src={url}
          style={{ width: '100%', height: '100%' }}
          frameBorder="0"
          allowFullScreen
          onLoad={() => handleIframeLoad(iframeId)}
        />
      </div>
    );
  };

  return (
    <>
      <div className="flex-wrap-container">
        {concatenedImgs.map((img, idx) => (
          <div key={`img-container-${idx}`} className="image-wrapper">
            {isPdf(img) ? (
              <div className="pdf-container" style={{ width: '100%', marginBottom: '10px' }}>
                <div style={{ height: '300px', border: '1px solid #ccc', borderRadius: '4px', overflow: 'hidden' }}>
                  {getPdfViewUrl(img) ? renderPdfViewer(getPdfViewUrl(img), `pdf-${idx}`) : (
                    <div className="pdf-placeholder" style={{
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: '#f8f9fa'
                    }}>
                      PDF não disponível
                    </div>
                  )}
                </div>
                <div className="pdf-actions" style={{ marginTop: '10px', display: 'flex', justifyContent: 'center', gap: '10px' }}>
                  <button
                    className="btn btn-outline-primary btn-sm"
                    onClick={() => openFullscreenPdfViewer(img)}
                    style={{ fontSize: '0.85rem' }}
                  >
                    Tela Cheia
                  </button>
                  <button
                    className="btn btn-outline-success btn-sm"
                    onClick={() => downloadPdf(img)}
                    style={{ fontSize: '0.85rem' }}
                  >
                    Baixar PDF
                  </button>
                </div>
              </div>
            ) : (
              <KeykenPayImg
                key={`${idx}`}
                img={img}
                handleNewImg={handleNewImg}
                type={idx === 0 ? 'MAIN' : 'USERAUX'}
                idx={idx === 0 ? undefined : idx - 1}
              />
            )}
          </div>
        ))}
      </div>

      <Modal isOpen={showModal} toggle={closeModal} size="lg" style={{ maxWidth: '900px' }}>
        <ModalHeader toggle={closeModal}>
          Visualizar PDF
        </ModalHeader>
        <ModalBody>
          {pdfUrl ? (
            <div style={{ height: '70vh', overflow: 'auto' }}>
              {renderPdfViewer(pdfUrl, 'modal-pdf', '100%')}
            </div>
          ) : (
            <div className="pdf-loading" style={{
              height: '70vh',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Carregando...</span>
              </div>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={closeModal}>
            Fechar
          </Button>{' '}
          <Button color="primary" onClick={downloadModalPdf} disabled={isLoading}>
            Baixar PDF
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
